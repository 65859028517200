import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { TranslatorProvider } from "react-translate";
import { LangContext } from "./components/Context";

import Home from "./pages/Home";
import Enterprise from "./pages/Enterprise/index";
import Tools from "./pages/Tools/index";
import Insights from "./pages/Insights/index";
import InsightsVideo from "./pages/InsightsVideo/index";
import Workshops from "./pages/Workshop/index";
import AboutUs from "./pages/AboutUs/index";
import Contact from "./pages/Contact/index";
import Health from "./pages/Health";
import NotFound from "./pages/NotFound/index";

import Layout from "./components/Layout/index";
import Footer from "./components/Footer/index";
import FreeTrial from "./pages/FreeTrial";

function App() {
  const [lang, setLang] = useState(localStorage.getItem("Lang") || "en");

  useEffect(() => {
    if (localStorage.getItem("Lang") === null) {
      localStorage.setItem("Lang", "en");
    }
  }, []);
  console.log('Negotiation by Design V2.2.1 - Hotfix - 15/11/2023');

  return (
    <LangContext.Provider value={{ lang, setLang }}>
      <TranslatorProvider translations={require("./translations/" + lang + ".json")}>
        <BrowserRouter>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/enterprise/:scroll?" component={Enterprise} />
              <Route exact path="/tools" component={Tools} />
              <Route exact path="/blog" component={Insights} />
              <Route exact path="/insightsVideo/:id" component={InsightsVideo} />
              <Route exact path="/workshops/:scroll?" component={Workshops} />
              <Route exact path="/about/:scroll?" component={AboutUs} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/negociaciones-salud" component={Health} />
              <Route exact path="/free-trial" component={FreeTrial} />
              <Route component={NotFound} />
            </Switch>
          </Layout>
          <Footer />
        </BrowserRouter>
      </TranslatorProvider>
    </LangContext.Provider>
  );

  // render(){
  //     let url  = window.location.href;
  //     if(url.search('/en') !== -1){
  //         return this._getLayout('en')
  //     }else{
  //         return this._getLayout('es');
  //     }
  // }
}

export default App;
