import React from 'react';
import './Loader.css';
import Icons from '../../assets/images/Icons';
import Logo from '../../assets/images/icono-negotiation-by-design.png';

function Loader({ Style, state }) {
  const colors = {
    amarillo: '#FFD367',
    morado: '#7263A0',
    naranja: '#EF8144',
    verde: '#4EA6AF',
  };

  if (Style === 'modal' && state === false) {
    return null;
  }

  return (
    <div
      className={
        Style === 'modal'
          ? 'opacity__container'
          : `loading__container ${
              Style === 'comp' ? 'loading__container_comp' : Style
            }`
      }
    >
      <div className="circle-container">
        <div className="loader__center">
          <img className="loader__naranja" src={Logo} alt="Icono loader" />
        </div>
        <div className="circle-container-icons deg0">
          <Icons name="Estrella" fill={colors.amarillo} />
        </div>
        <div className="circle-container-icons deg32">
          <Icons name="Tuerca" fill={colors.morado} />
        </div>
        <div className="circle-container-icons deg65">
          <Icons name="Llave" fill={colors.naranja} />
        </div>
        <div className="circle-container-icons deg98">
          <Icons name="Estrella" fill={colors.morado} />
        </div>
        <div className="circle-container-icons deg130">
          <Icons name="Tuerca" fill={colors.amarillo} />
        </div>
        <div className="circle-container-icons deg163">
          <Icons name="Llave" fill={colors.verde} />
        </div>
        <div className="circle-container-icons deg196">
          <Icons name="Estrella" fill={colors.naranja} />
        </div>
        <div className="circle-container-icons deg229">
          <Icons name="Tuerca" fill={colors.morado} />
        </div>
        <div className="circle-container-icons deg261">
          <Icons name="Llave" fill={colors.amarillo} />
        </div>
        <div className="circle-container-icons deg294">
          <Icons name="Estrella" fill={colors.naranja} />
        </div>
        <div className="circle-container-icons deg327">
          <Icons name="Tuerca" fill={colors.verde} />
        </div>
      </div>
    </div>
  );
}

export default Loader;
