import React from 'react';
import { translate } from 'react-translate';
import { Link } from 'react-router-dom';

import './Footer.css';

function Footer(props) {
  let { t } = props;
  return (
    <footer className="Footer__container">
      <div className="Footer__section_medium">
        <div className="Section__list dropdown02">
          <h5>{t('title_negotiationS')}</h5>
          <div className="Content__items">
            <Link to="/contact">{t('subtitle_contact')}</Link>
          </div>
        </div>
        <div className="Section__list">
          <h5>{t('title_enterprise')}</h5>
          <div className="Content__items">
            <Link to="/enterprise/seccionCardsOne">{t('subtitle_onSite')}</Link>
            <Link to="/enterprise/seccionCardsTwo">{t('subtitle_coaching')}</Link>
            <Link to="/enterprise/seccionCardsOne">{t('subtitle_consulting')}</Link>
            <Link to="/enterprise/seccionCardsTwo">{t('subtitle_keynotes')}</Link>
            <Link to="/enterprise/seccionCardsOne">{t('subtitle_Capacity')}</Link>
          </div>
        </div>
        <div className="Section__list">
          <h5>{t('title_resources')}</h5>
          <div className="Content__items">
            <Link to="/tools">{t('subtitle_negotitationC')}</Link>
          </div>
        </div>
        <div className="Section__list dropdown02">
          <h5>{t('title_about')}</h5>
          <div className="Content__items">
            <Link to="/about/differentiators">{t('subtitle_differentiators')}</Link>
            {/* <Link to="/about/clientes">{t('subtitle_ourClients')}</Link> */}
            <Link to="/about/whereWeWork">{t('subtitle_whereWeWork')}</Link>
            <Link to="/about/ourTeam">{t('subtitle_ourTeam')}</Link>
          </div>
        </div>
        <div className="Section__list dropdown02">
          <h5>{t('title_connet')}</h5>
          <div className="Content__items">
            <Link to="/contact">{t('subtitle_contact')}</Link>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/pablorestrepo/"
            >
              LinkedIn
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://negotiationbydesign.com/blog/">
              Vlog
            </a>
          </div>
        </div>
      </div>
      <div className="Footer__section_small">
        <a rel="noopener noreferrer" href="https://wisengine-nbd.s3.amazonaws.com/PDF/AVISO+DE+PRIVACIDAD+NEGOTIATION+BY+DESIGN.pdf" target="_blank">
          {t('terms_of_service')}
        </a>
        &nbsp; | &nbsp;
        <a rel="noopener noreferrer" href="https://wisengine-nbd.s3.amazonaws.com/PDF/POLITICA+DE+TRATAMIENTO+DE+DATOS+PERSONALES+NEGOTIATION+BY+DESIGN.pdf" target="_blank">
          {t('privacy_policy')}
        </a>
        &nbsp; | &nbsp;
        {/* <a href="https://negotiationbydesign.com/wp-content/uploads/2019/02/NbD-Copyright-and-IP-Policy.pdf">
            &nbsp; Copyright &amp; IP Policy &nbsp;
          </a> */}
        Copyright 2021&nbsp;-&nbsp;Negotiation by Design&nbsp;-&nbsp;
        {t('powerd_by')}&nbsp;Wisengine
      </div>
    </footer>
  );
}

export default translate('footer')(Footer);