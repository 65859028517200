import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-translate';
import { Helmet } from 'react-helmet';

import './Enterprise.css';

import Header from '../../components/Header/';
import Card from '../../components/Card';

import ImageBanner from '../../assets/images/banner/Enterprise-main-banner-1-1.png';
import Image1 from '../../assets/images/Services/Services_1.png';
import Image2 from '../../assets/images/Services/Services_2.png';
import Image2_2 from '../../assets/images/Services/Services_2-es.png';
import Image3 from '../../assets/images/Services/Services_3.png';
import Image4 from '../../assets/images/Services/Services_4.png';
import Image5 from '../../assets/images/Services/Services_5.png';
import Image6 from '../../assets/images/Services/Services_6.png';

import consulting_img from '../../assets/images/Home/popup/consulting.png';
import capacity_building_img from '../../assets/images/Home/popup/capacity_building.png';
import training_img from '../../assets/images/Home/popup/Website_Services-full-Training2.png';
import platform_img from '../../assets/images/Home/popup/platform.png';
import keynotes_img from '../../assets/images/Home/popup/keynotes.png';
import coaching_img from '../../assets/images/Home/popup/coaching.png';

function Enterprise(props) {
  let { t } = props;
  const lang = localStorage.getItem('Lang');
  const { match: { params } } = props
  const seccionCardsOne = useRef();
  const seccionCardsTwo = useRef();

  useEffect(() => {
    const scroll = (value) => {
      if (value === "seccionCardsOne") {
        window.scroll({
          top: seccionCardsOne.current.offsetTop - 100,
          left: 0,
          behavior: "smooth",
        });
      } else if (value === "seccionCardsTwo") {
        window.scroll({
          top: seccionCardsTwo.current.offsetTop - 100,
          left: 0,
          behavior: "smooth",
        });
      } else if (value === undefined) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    };
    scroll(params.scroll);
  }, [params.scroll]);


  const PupUp = {
    consulting: {
      title: 'consulting_title',
      subtitle: 'consulting_subtitle',
      img: consulting_img,
      text1: 'consulting_text1',
      text2: 'consulting_text2',
      button: 'consulting_button',
    },
    platform: {
      title: 'platform_title',
      subtitle: 'platform_subtitle',
      img: platform_img,
      strongtext1: 'platform_strongtext1',
      button: 'platform_button',
    },
    capacity_building: {
      title: 'capacity_building_title',
      subtitle: 'capacity_building_subtitle',
      img: capacity_building_img,
      text1: 'capacity_building_text1',
      text2: 'capacity_building_text2',
      text3: 'capacity_building_text3',
      text4: 'capacity_building_text4',
      button: 'capacity_building_button',
    },
    training: {
      title: 'training_title',
      subtitle: 'training_subtitle',
      before_img: 'training_beforeImg',
      img: training_img,
      text1: 'training_text1',
      text2: 'training_text2',
      text3: 'training_text3',
      text4: 'training_text4',
      button: 'training_button',
    },
    keynotes: {
      title: 'keynotes_title',
      subtitle: 'keynotes_subtitle',
      img: keynotes_img,
      name_list: 'keynotes_list',
      list: [
        {
          id: 1,
          text: 'keynotes_list1',
        },
        {
          id: 2,
          text: 'keynotes_list2',
        },
        {
          id: 3,
          text: 'keynotes_list3',
        },
        {
          id: 4,
          text: 'keynotes_list4',
        },
        {
          id: 5,
          text: 'keynotes_list5',
        },
        {
          id: 6,
          text: 'keynotes_list6',
        },
        {
          id: 7,
          text: 'keynotes_list7',
        },
      ],
      button: 'keynotes_button',
    },
    coaching: {
      title: 'coaching_title',
      subtitle: 'coaching_subtitle',
      img: coaching_img,
      text1: 'coaching_text1',
      text2: 'coaching_text2',
      column: '1',
      button: 'coaching_button',
    },
  };


  return (
    <div className="Enterprise__page">
      <Helmet>
        <title>Negotiation by Design | Enterprise</title>
      </Helmet>
      <Header title="title_enterprise" ImageBanner={ImageBanner} />
      <div className="Enterprise__section--small">
        <div className="section--paragraph">
          <div dangerouslySetInnerHTML={{ __html: t('list_text') }} />
        </div>
        <div className="section--list">
          <p>{t('list_title')}</p>
          <ul>
            <li>{t('list_item_1')}</li>
            <li>{t('list_item_2')}</li>
            <li>{t('list_item_3')}</li>
            <li>{t('list_item_4')}</li>
            <li>{t('list_item_5')}</li>
            <li>{t('list_item_6')}</li>
          </ul>
        </div>
      </div>
      <div className="Enterprise__section--medium">
        <h2>{t('title_1')}</h2>
        <h5>{t('subtitle')}</h5>
        <div className="Enterprise__cards" ref={seccionCardsOne}>
          <Card
            image={Image1}
            title={t('card_title_1')}
            popup={true}
            popup_title={PupUp.capacity_building.title}
            popup_subtitle={PupUp.capacity_building.subtitle}
            popup_img={PupUp.capacity_building.img}
            popup_text1={PupUp.capacity_building.text1}
            popup_text2={PupUp.capacity_building.text2}
            popup_text3={PupUp.capacity_building.text3}
            popup_text4={PupUp.capacity_building.text4}
            popup_button={PupUp.capacity_building.button}
          />
          <Card
            image={lang === "en" ? Image2 : Image2_2}
            title={t('card_title_2')}
            popup={true}
            popup_title={PupUp.consulting.title}
            popup_subtitle={PupUp.consulting.subtitle}
            popup_img={PupUp.consulting.img}
            popup_text1={PupUp.consulting.text1}
            popup_text2={PupUp.consulting.text2}
            popup_button={PupUp.consulting.button}
          />
          <Card
            image={Image3}
            title={t('card_title_3')}
            popup={true}
            popup_title={PupUp.training.title}
            popup_subtitle={PupUp.training.subtitle}
            popup_before_img={PupUp.training.before_img}
            popup_img={PupUp.training.img}
            popup_text1={PupUp.training.text1}
            popup_text2={PupUp.training.text2}
            popup_text3={PupUp.training.text3}
            popup_text4={PupUp.training.text4}
            popup_button={PupUp.training.button}
          />
        </div>

        <div className="Enterprise__cards" ref={seccionCardsTwo}>
          <Card
            image={Image4}
            title={t('card_title_4')}
            popup={true}
            popup_title={PupUp.coaching.title}
            popup_subtitle={PupUp.coaching.subtitle}
            popup_img={PupUp.coaching.img}
            popup_text1={PupUp.coaching.text1}
            popup_text2={PupUp.coaching.text2}
            popup_text3={PupUp.coaching.text3}
            popup_text4={PupUp.coaching.text4}
            popup_button={PupUp.coaching.button}
          />
          <Card
            image={Image5}
            title={t('card_title_5')}
            popup={true}
            popup_title={PupUp.keynotes.title}
            popup_subtitle={PupUp.keynotes.subtitle}
            popup_img={PupUp.keynotes.img}
            name_list={PupUp.keynotes.name_list}
            list={PupUp.keynotes.list}
            popup_button={PupUp.keynotes.button}
          />
          <Card
            image={Image6}
            title={t('card_title_6')}
            popup={true}
            popup_title={PupUp.platform.title}
            popup_subtitle={PupUp.platform.subtitle}
            popup_img={PupUp.platform.img}
            popup_strongtext1={PupUp.platform.strongtext1}
            popup_button={PupUp.platform.button}
          />
        </div>
      </div>
      <div className="Enterprise__section">
        <h3 className="section--title">{t('title_2')}</h3>
        <Link className="Enterprise__button " to="/contact">
          {t('title_button')}
        </Link>
      </div>
    </div>
  );
}

export default translate('enterprise')(Enterprise);
