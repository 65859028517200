import React, { Fragment } from "react";

import "./Header.css";

import { translate } from "react-translate";
import { Link } from "react-scroll";

function Header(props) {
  let { t } = props;
  return (
    <Fragment>
      <div className="Header__container">
        <div className={props.fixedbanner ? "Header__container_baner" : "Header__container--content"}>
          <div className="Header__content--text">
            <div className="Container__text">
              <div
                className="Text__title"
                dangerouslySetInnerHTML={{ __html: t(props.title) }}
              ></div>
              <div className="Text__paragraph">
                {props.paragraph ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: t(props.paragraph) }}
                  ></div>
                ) : (
                  ""
                )}
              </div>
              <div className="Text__negrita">
                {props.negrita ? t(props.negrita) : ""}
              </div>
              {props.scroll ? (
                <Link
                  activeClass="active"
                  to={props.scroll}
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  <div className="Text__one">
                    {props.text ? t(props.text) : ""}
                    {props.text ? (
                      <div className="Container__arrow">
                        <span className="Arrow__orange"></span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            className={
              props.tall ? "Header__content--tall" : "Header__content--media"
            }
          >
            {props.ImageBanner ? (
              <React.Fragment>
                <img
                  className="Header__content--img"
                  src={props.ImageBanner}
                  alt="Banner Header"
                />
                <div className="Container__down">
                  {props.scroll ? (
                    <Link
                      activeClass="active"
                      to={props.scroll}
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={500}
                    >
                      <img
                        className="Image__down"
                        src={props.ImageDown}
                        alt="Icon Down"
                      />
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </React.Fragment>
            ) : (
              <div className="Video">
                <div className="Container__video">
                  <iframe
                    title="Video banner"
                    src={`https://www.youtube.com/embed/_JJSQGhORoc`}
                    frameBorder="0"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.ImageSilla ? (
        <span>
          <img className="Image__silla silla" src={props.ImageSilla} alt="Imagen Silla" />
        </span>
      ) : (
        ""
      )}
    </Fragment>
  );
}
export default translate("header")(Header);