import React from "react";

import "./FreeTrial.css";
import { translate } from "react-translate";

import { Link } from "react-router-dom";

function FreeTrial(props) {
  let { t } = props;
  return (
    <div className="FreeTrial__page">
      <div className="FreeTrial__container--card">
        <div className="Card__information">
          <h5>{t("title")}</h5>
          <p>{t("text")}</p>
          <p>{t("text_2")} </p>
          <div className="Card__information--button">
            <a href="http://negotiationbydesign.com/pricing/">
              {t("text_button")}
            </a>
          </div>
        </div>
        <div className="Card__from">
          <div className="Card_from--container">
            <h5>{t("title_2")}</h5>
            <input
              className="Card__from--input"
              type="text"
              placeholder={t("firstName")}
            />
            <input
              className="Card__from--input"
              type="text"
              placeholder={t("lastName")}
            />
            <input
              className="Card__from--input"
              type="text"
              placeholder={t("email")}
            />
            <input
              className="Card__from--input"
              type="password"
              placeholder={t("password")}
            />
            <div className="Card__from--check">
              <label>
                <input type="checkbox" />
                {t("text_check")}
                {/* <a href="#">{t("text_check_2")}</a> */}
              </label>
            </div>
            <div className="Card__from--button">
              <button id="submit" type="submit">
                {t("Card_from_button")}
              </button>
            </div>
          </div>
          <Link className="Card__from--message">
            {t("from_messaje")}
            <span>{t("from_messaje_2")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default translate("freeTrial")(FreeTrial);
