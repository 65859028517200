import React from 'react'

class NotFound extends React.Component {
    render() {
        return(
            <h1>NotFound</h1>
        )
    }
}

export default NotFound;