import React from "react";

import Academic_1 from "../../../../assets/images/Academic/ACADEMIC_1.png";
import Academic_2 from "../../../../assets/images/Academic/ACADEMIC_2.png";
import Academic_3 from "../../../../assets/images/Academic/ACADEMIC_3.png";
import Academic_4 from "../../../../assets/images/Academic/ACADEMIC_4.png";

function Academic() {

  return (
    <React.Fragment> 
        <div className="Container__item">
          <div className="Corporate__container">
            <img  src={Academic_1} className="Corporate__container--img" alt="Icon Slider Academic" />
          </div>
          <div className="Corporate__container">
            <img  src={Academic_2} className="Corporate__container--img" alt="Icon Slider Academic" />
          </div>
          <div className="Corporate__container">
            <img  src={Academic_3} className="Corporate__container--img" alt="Icon Slider Academic" />
          </div>
          <div className="Corporate__container">
            <img  src={Academic_4} className="Corporate__container--img" alt="Icon Slider Academic" />
          </div>
        </div>
    </React.Fragment>
  );
}

export default Academic;
