import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';
import { translate } from "react-translate";
import { Link } from 'react-router-dom';
import dataBlog from "../../components/ObjectData/dataBlog"

import Header from "../../components/Header";
import CardVideo from "../../components/CardVideo";

import ImageBanner from "../../assets/images/banner/Banner-blog.png";
import IconVimeo from "../../assets/images/Icon/vimeo-logo-square.png";
import IconLinkedin from "../../assets/images/Icon/linkedin.png";

import "./Insights.css";

function Insights(props) {
  let { t } = props;
  const lang = localStorage.getItem("Lang");
  const { match: { params } } = props
  useEffect(() => {
    const scroll = (value) => {
      if (value === undefined) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    };
    scroll(params.scroll);
  }, [params.scroll]);

  return (
    <>
      <div className="Insights__page">
        <Helmet>
          <title>Negotiation by Design | Blog</title>
        </Helmet>
        <Header
          title="title_insights"
          paragraph="paragraph_insights"
          ImageBanner={ImageBanner}
        />
        <div className="Insights__content">
          <div className="Insights__content_cards">
            {
              dataBlog.length > 0 ?
                dataBlog.sort((a, b) =>
                  a.orderView < b.orderView && -1
                ).map((item, index) =>
                item[`image_${lang}`]  &&
                  <div key={index} className="Insights__content--cards">
                    <CardVideo
                      imageHeader={item[`image_${lang}`]}
                      title={item[`title_${lang}`]}
                      text={item[`text_${lang}`]}
                      id={item.id}
                    />
                  </div>
                )
                :
                <h3>No se han cargado blogs</h3>
            }
          </div>
          <div className="Insights__content_social">
            <div className="Content__cards--information">
              <div className="Cards__information--socialMedia">
                <h5>{t('connect')}</h5>
                <div className="SocialMedia__icons">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/pablorestrepo/"
                  >
                    <img src={IconLinkedin} alt="Icon__likedin" />
                  </a>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://vimeo.com/negotiationbydesign"
                  >
                    <img src={IconVimeo} alt="Icon__Vimeo" />
                  </a>
                </div>
              </div>
              <div className="Cards__information--contact">
                <h5>{t('vlog')}</h5>
                <p>{t('view_spanish')}</p>
                <div className="Cards__information--contact--button">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={t('youtube_channel')}
                    title=""
                  >
                    {t('view_now')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Insights__section">
        <h3 className="section--title">{t('title_2')}</h3>
        <Link className="Insights__button " to="/contact">
          {t('title_button')}
        </Link>
    </div>
  </>
  );
}

export default translate("insights")(Insights);