import React from "react";

import Government_1 from "../../../../assets/images/Government/Government-05.png";
import Government_2 from "../../../../assets/images/Government/Government-06.png";
import Government_3 from "../../../../assets/images/Government/Government-07.png";
import Government_4 from "../../../../assets/images/Government/Government-08.png";
import Government_5 from "../../../../assets/images/Government/Government-09.png";
import Government_6 from "../../../../assets/images/Government/Government-10.png";

function Government() {

  return (
    <React.Fragment>
      <div className="Container__item">
        <div className="Corporate__container">
          <img src={Government_1} className="Corporate__container--img" alt="Icon Slider Government" />
        </div>
        <div className="Corporate__container">
          <img src={Government_2} className="Corporate__container--img" alt="Icon Slider Government" />
        </div>
        <div className="Corporate__container">
          <img src={Government_3} className="Corporate__container--img" alt="Icon Slider Government" />
        </div>
      </div>
      <div className="Container__item">
        <div className="Corporate__container">
          <img src={Government_4} className="Corporate__container--img" alt="Icon Slider Government" />
        </div>
        <div className="Corporate__container">
          <img src={Government_5} className="Corporate__container--img" alt="Icon Slider Government" />
        </div>
        <div className="Corporate__container">
          <img src={Government_6} className="Corporate__container--img" alt="Icon Slider Government" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Government;
