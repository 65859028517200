import React, { useState, useEffect } from 'react';
import { catalogs, form } from '../../api-akeportal';
import { Helmet } from 'react-helmet';
import { translate } from 'react-translate';
import { Element } from 'react-scroll';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import ModalTools from '../../components/ModalTools';

import UpdateInput from "../../components/UpdateInput";
import Input from "../../components/Input";
import Modal from "../../components/Modal";

import ImageBanner from '../../assets/images/Blue-canvas-02.png';
import ImageBannerEs from '../../assets/images/banner/Es/NbD_ES-02.png';
import ImageDown from '../../assets/images/banner/Canvas-downloads-73.png';

import imageprincipal from '../../assets/images/Website_Tools-Orange-example-1024x603.png';
import Image1 from '../../assets/images/Website_Tools-Language-EN-blue.png';
import Image2 from '../../assets/images/Website_Tools-Language-FR-blue.png';
import Image3 from '../../assets/images/Website_Tools-Language-IT-blue.png';
import Image4 from '../../assets/images/Website_Tools-LAnguage-ES-blue.png';
import Image5 from '../../assets/images/Website_Tools-Language-JA-blue.png';
import Image6 from '../../assets/images/translate-the-canvas-101.png';
import LogoNbD from "../../assets/images/Health/logo-nbd.svg";
import ImageStartIzq from "../../assets/images/Health/StartIzq.svg";
import ImageStartDer from "../../assets/images/Health/StartDer.svg";
import './Tools.css';

function Tools(props) {
  let { t } = props;
  const { match: { params } } = props
  const [cookies, setCookies] = useCookies();
  const lang = localStorage.getItem("Lang");
  const [successful, setSuccessful] = useState(false);

  const [country, setCountry] = useState([]);
  const [isVideo, setisVideo] = useState("");
  // let text = "P&acute;erez"
  /**
   * Modal New
   */
  const [isOpen, setIsOpen] = useState(false);
  const [fieldEmpty, setFieldEmpty] = useState([]);
  const [ToolsLang, setToolsLang] = useState("");
  const [term, setTerm] = useState("N");
  const [flag, setFlag] = useState(0);
  const [register, setRegister] = useState({
    name: { value: "", error: false },
    last_name: { value: "", error: false },
    email: { value: "", error: false },
    use_canvas: { value: "", error: false },
    country_id: { value: "", error: false },
    city: { value: "", error: false },
    company: { value: "", error: false },
  });

  const topFive = {
    id: "",
    titulo: "",
    href_es: "https://www.youtube.com/watch?v=l-AfFmfbYvQ",
    href_en: "https://www.youtube.com/watch?v=l-AfFmfbYvQ",
    href_it: null,
    content_es: "https://wisengine-nbd.s3.amazonaws.com/website/tools/tools-top-question-es.png",
    content_en: "https://wisengine-nbd.s3.amazonaws.com/website/tools/tools-top-question-en.png",
    content_it: null,
  }
  const iconPlay = {
    id: "",
    titulo: "",
    href_es: "https://www.youtube.com/c/NegotiationbyDesign",
    href_en: "https://www.youtube.com/c/NegotiationbyDesign",
    href_it: null,
    content_es: "https://wisengine-nbd.s3.amazonaws.com/website/tools/tools-icon-blog-play-es.png",
    content_en: "https://wisengine-nbd.s3.amazonaws.com/website/tools/tools-icon-blog-play-en.png",
    content_it: null,
  }
  const vblogs = {
    id: "",
    titulo: "",
    href_es: "https://youtu.be/pzOW9IotiVo",
    href_en: "https://youtu.be/pzOW9IotiVo",
    href_it: null,
    content_es: "https://wisengine-nbd.s3.amazonaws.com/website/tools/tools-image-video-blog-es.jpg",
    content_en: "https://wisengine-nbd.s3.amazonaws.com/website/tools/tools-image-video-blog-en.jpg",
    content_it: null,
  }
  const aboutTools = [
    {
      id: 1,
      text_es: "Descarga las instrucciones del Canvas de Negociaciones",
      text_en: "Download the instructions of the Negotiation Canvas",
      href_es: "https://wisengine-nbd.s3.amazonaws.com/PDF/Instrucciones+Canvas+de+Negociaci%C3%B3n.pdf",
      href_en: "https://wisengine-nbd.s3.amazonaws.com/PDF/Negotiation+Canvas+instructions.pdf",
      href_it: null,
      content_es: "https://wisengine-nbd.s3.amazonaws.com/website/tools/tools-image-download-canvas-es.png",
      content_en: "https://wisengine-nbd.s3.amazonaws.com/website/tools/tools-image-download-canvas-en.png",
      content_it: null,
      alt: "Canvas de negociacion"
    },
    {
      id: 2,
      text_es: "Descarga las instrucciones del proceso de negociación",
      text_en: "Download the instructions of the negotiation process",
      href_es: "https://wisengine-nbd.s3.amazonaws.com/PDF/Instrucciones+proceso+de+negociaci%C3%B3n.pdf",
      href_en: "https://wisengine-nbd.s3.amazonaws.com/PDF/Negotiation+process+instructions.pdf",
      href_it: null,
      content_es: "https://wisengine-nbd.s3.amazonaws.com/website/tools/tools-image-download-process-es.png",
      content_en: "https://wisengine-nbd.s3.amazonaws.com/website/tools/tools-image-download-process-en.png",
      content_it: null,
      alt: "Proceso de negociacion"
    }
  ]

  useEffect(() => {
    const scroll = (value) => {
      if (value === undefined) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    };
    scroll(params.scroll);
  }, [params.scroll]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let data = await catalogs();
  
    const sortedCountries = data.countrys.sort((a, b) => a.NAME.localeCompare(b.NAME));
    setCountry(sortedCountries);
  };

  const handleVideo = (e) => {
    e.preventDefault();
    setisVideo("true");
  };
  const ConfirmChecked = () => {
    if (term === "N") {
      setTerm("Y");
      setFlag(0);
    } else {
      setTerm("N");
    }
  };

  const Redirect = (e) => {
    // console.log(e);
    return <Redirect to="/contact" />;
  };

  const cleanRegister = () => {
    setRegister({
      name: { value: "", error: false },
      last_name: { value: "", error: false },
      email: { value: "", error: false },
      use_canvas: { value: "", error: false },
      country_id: { value: "", error: false },
      city: { value: "", error: false },
      profession: { value: "", error: false },
      company: { value: "", error: false },
      position: { value: "", error: false },
      origin: { value: "", error: false },
      origin_field: { value: "", error: false },
      interest: { value: "", error: false },
    });
    setTerm("N")
  };

  const closeModal = () => {
    setIsOpen(false);
    cleanRegister();
  };

  const handleChange = (e, field) => {
    // Actualizar localmente en la función la neva data del perfil fisico.
    let dataRegister = {
      ...register,
      [field]: {
        ...register[field],
        value: e.target.value,
        error: false,
      },
    };

    // validación de campos vacios
    if (
      dataRegister["name"].value + "" === "" ||
      dataRegister["last_name"].value + "" === "" ||
      dataRegister["email"].value + "" === "" ||
      dataRegister["use_canvas"].value + "" === "" ||
      dataRegister["country_id"].value + "" === "" ||
      dataRegister["city"].value + "" === "" ||
      dataRegister["company"].value + "" === ""
    ) {
      setFieldEmpty(true);
    } else {
      setFieldEmpty(false);
    }

    setRegister(dataRegister);
  };

  /**
   * Send
   */

  // Funcion para validar los campos
  const validateEmpy = async (fields, data, validator) => {
    let newData = register;
    // console.log(newData);
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i];
      let validate = newData[field].value + "".trim();
      if (validate.length === 0) {
        newData = {
          ...newData,
          [field]: {
            ...newData[field],
            error: true,
          },
        };
      }
    }

    if (register === newData) {
      return true;
    } else {
      setRegister(newData);
      return false;
    }
  };

  const sendRegister = async (canvasLang, toolSelected) => {
    // console.log("Envío de formulario: ", register);
    let dataForm = {};

    if (!cookies.DataUser) {
      let validate = await validateEmpy([
        "name",
        "last_name",
        "email",
        "use_canvas",
        "country_id",
        "city",
        "company",
      ]);

      if (term === "N" || term === "") {
        setFlag(1);
        return false;
      };

      if (validate) {
        //eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(register['email'].value)) {
          let newData = {
            ...register,
            'email': {
              ...register['email'],
              error: true,
            },
          };
          setRegister(newData);
          return;
        } else {
          // console.log("Cumple con los requisitos para enviar el formulario:");
          // console.log("Data final:", register);
          console.log("no tiene cookies");

          dataForm = {
            form: "DOWNLOAD",
            lang: lang,
            name: register["name"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
            last_name: register["last_name"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
            email: register["email"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
            previously: register["use_canvas"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
            country_id: register["country_id"].value,
            city: register["city"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
            company: register["company"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
            tool: toolSelected,
            tool_lang: ToolsLang,
          };

          console.log("Datos para enviar creado desde el formulario: ", dataForm);

        }
      };

    } else {
      console.log("por cookie");
      console.log('cookies', cookies);
      console.log('cookies.DataUser', cookies.DataUser);

      dataForm = {
        form: "DOWNLOAD",
        lang: lang,
        name: cookies.DataUser["name"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
        last_name: cookies.DataUser["last_name"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
        email: cookies.DataUser["email"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
        previously: cookies.DataUser["use_canvas"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
        country_id: cookies.DataUser["country_id"].value,
        city: cookies.DataUser["city"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
        company: cookies.DataUser["company"].value.replace(/["]+/g, '').replace(/[']+/g, ''),
        tool: toolSelected,
        tool_lang: canvasLang,
      };

      console.log("Datos para enviar desde la cockie: ", dataForm);
    }

    // Logica para guardar y abrir documento en nueva pestaña:
    try {
      console.log("llamado del servicio de insersión: ");
      let response = await form(dataForm);
      console.log("response form canvas: ", response);
      setSuccessful(true);

      if (!cookies.DataUser) {
        setCookies("DataUser", register);
      };

      if (toolSelected === 'CANVAS') {
        if (canvasLang === 'en') {
          window.open(
            'https://wisengine-nbd.s3.amazonaws.com/ImagesPDF/Negotiation_Canvas_en.pdf',
            '_blank'
          );
        } else if (canvasLang === 'es') {
          window.open(
            'https://wisengine-nbd.s3.amazonaws.com/ImagesPDF/Canvas_de_Negociacion_es.pdf',
            '_blank'
          );
        } else if (canvasLang === 'fr') {
          window.open(
            'https://wisengine-nbd.s3.amazonaws.com/ImagesPDF/Negotiation+Canvas_Letter.pdf',
            '_blank'
          );
        } else if (canvasLang === 'it') {
          window.open(
            'https://wisengine-nbd.s3.amazonaws.com/ImagesPDF/Canvas+di+Negoziazione_Lettera.pdf',
            '_blank'
          );
        }
      } else if (toolSelected === 'PROCESS_INSTRUCTIONS') {

        let tool_to_send = aboutTools.filter(item => item.id === 2)[0];
        window.open(tool_to_send[`href_${lang}`], "_blank");

      } else if (toolSelected === 'CANVAS_INSTRUCTIONS') {

        let tool_to_send = aboutTools.filter(item => item.id === 1)[0];
        window.open(tool_to_send[`href_${lang}`], "_blank");

      }

      closeModal();
    } catch (error) {
      console.log(error);
      closeModal();
    }

  };

  /**
   * End Send
   */
  const HandleLanguage = (lg) => {
    if (cookies.DataUser) {
      sendRegister(lg, "CANVAS");
      console.log(`entre con cookies`);
    } else {
      setToolsLang(lg);
      setIsOpen("CANVAS");
    }
  };


  return (
    <div className="Tools__page">
      <Helmet>
        <title>Negotiation by Design | Tools</title>
      </Helmet>
      <Header
        title="title_tools"
        ImageBanner={lang === "en" ? ImageBanner : ImageBannerEs}
        ImageDown={ImageDown}
        scroll="tools"
      />
      <div className="Tools__section_up">
        <div className="section__up--content">
          <div className="Content__text">
            <div
              className="Content__text--negrita"
              dangerouslySetInnerHTML={{ __html: t("text_style") }}
            />
          </div>
          <div className="Content__orange">{t("subtitle")}</div>
        </div>
        <div className="Content__image">
          {isVideo ? (
            <div className={`Video__tools ${isVideo}`}>
              <div className="Container__video">
                <iframe
                  title="Video la naranja"
                  allow="autoplay"
                  src={
                    lang === "en"
                      ? `https://player.vimeo.com/video/421232453?autoplay=1`
                      : `https://player.vimeo.com/video/424938232?autoplay=1`
                  }
                  frameBorder="0"
                />
              </div>
            </div>
          ) : (
            <img
              onClick={handleVideo}
              src={lang === "en" ? imageprincipal : imageprincipal}
              alt="Ejemplo de la naranja"
            />
          )}
        </div>
      </div>
      <Element name="tools"></Element>
      <div className="Tools__section_gray">
        <div className="Tools__section--medium">
          <h2>{t("title_1")}</h2>
          <h5>{t("subtitle_1")}</h5>
          <div className="Section__container--cards">
            <div>
              <Modal
                isOpen={isOpen}
                onClose={closeModal}
                closeButton={true}
                background={"gray"}
              >
                <div className="Health__modal_content">
                  <div className="Health__container_form">
                    <div className="Health__form_header">
                      <div className="Health__form_title">{t("title")}</div>
                      <div className="Health__form_subtitle">{t("text")}</div>
                    </div>
                    <div className="Health__contianer_input">
                      <UpdateInput
                        title={t("name") + "*"}
                        type="text"
                        setPlacerholder={t("placeholder_name")}
                        value={register["name"].value}
                        alert={register["name"].error}
                        onChange={(e) => {
                          handleChange(e, "name");
                        }}
                      />
                    </div>
                    <div className="Health__contianer_input">
                      <UpdateInput
                        title={t("last_name") + "*"}
                        type="text"
                        setPlacerholder={t("placeholder_last_name")}
                        value={register["last_name"].value}
                        alert={register["last_name"].error}
                        onChange={(e) => {
                          handleChange(e, "last_name");
                        }}
                      />
                    </div>
                    <div className="Health__contianer_input">
                      <UpdateInput
                        title={t("email") + "*"}
                        type="text"
                        setPlacerholder={t("placeholder_email")}
                        value={register["email"].value}
                        alert={register["email"].error}
                        onChange={(e) => {
                          handleChange(e, "email");
                        }}
                      />
                    </div>
                    <div className="Health__contianer_input">
                      <UpdateInput
                        title={t("company") + "*"}
                        type="text"
                        setPlacerholder={t("placeholder_company")}
                        value={register["company"].value}
                        alert={register["company"].error}
                        onChange={(e) => {
                          handleChange(e, "company");
                        }}
                      />
                    </div>
                    <div className="Health__contianer_input">
                      <UpdateInput
                        title={t("country") + "*"}
                        type="select"
                        setPlacerholder={t("placeholder_country")}
                        value={register["country_id"].value}
                        alert={register["country_id"].error}
                        onChange={(e) => {
                          handleChange(e, "country_id");
                        }}
                        options={country.map((country) => country.ID)}
                        options_name={country.map((country) => country.NAME)}
                      /> 
                    </div>
                    <div className="Health__contianer_input">
                      <UpdateInput
                        title={t("city") + "*"}
                        type="text"
                        setPlacerholder={t("placeholder_city")}
                        value={register["city"].value}
                        alert={register["city"].error}
                        onChange={(e) => {
                          handleChange(e, "city");
                        }}
                      />
                    </div>
                    <div className="Health__contianer_input">
                      <UpdateInput
                        title={t("use_canvas") + "*"}
                        type="radio"
                        name="use_canvas"
                        value={register["use_canvas"].value}
                        onChange={(e) => {
                          handleChange(e, "use_canvas");
                        }}
                        options={["Si", "No"]}
                      />
                    </div>
                    <div className="Health__contianer_input">
                      <Input
                        type="checkbox"
                        id="checkbox"
                        name="term"
                        text={t("term_and")}
                        confirm={term}
                        onChange={(e) => ConfirmChecked()}
                      />
                      <div className="msg_error">
                        {flag === 1 && t("accept_term")}
                      </div>
                    </div>
                  </div>
                  <div className="Health__container_bottom">
                    <button
                      className={
                        !fieldEmpty && term === "Y"
                          ? "Health__button_form button_enabled"
                          : "Health__button_form button_disabled"
                      }
                      onClick={() => sendRegister(ToolsLang, isOpen)}
                    >
                      Registrar
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="Section__cards--lang">
              <ModalTools
                image={Image1}
                title="Download the Canvas"
                orange={true}
                // contents={contents.en}
                country={country}
                lg="en"
                onClick={() => HandleLanguage("en")}
              />

              <ModalTools
                image={Image4}
                title="Descargar el Canvas"
                orange={true}
                // contents={contents.es}
                country={country}
                small="yes"
                lg="es"
                onClick={() => HandleLanguage("es")}
              />
            </div>
            <div className="Section__cards--lang">
              <ModalTools
                image={Image2}
                title="Téléchargez le Canvas"
                orange={true}
                // contents={contents.fr}
                country={country}
                small="yes"
                lg="fr"
                onClick={() => HandleLanguage("fr")}
              />

              <ModalTools
                image={Image5}
                title="キャンバスをダウンロードする"
                orange={true}
                // contents={contents.ja}
                country={country}
                lg="ja"
                onClick={() => HandleLanguage("ja")}
              />
            </div>
            <div className="Section__cards--lang">
              <ModalTools
                image={Image3}
                title="Scarica Il Canvas di Negoziazione"
                orange={true}
                // contents={contents.it}
                country={country}
                small="yes"
                lg="it"
                onClick={() => HandleLanguage("it")}
              />
              <ModalTools
                image={Image6}
                title="Help us translate the Canvas so everyone can use it!"
                orange={true}
                // contents={contents.en}
                HandleLanguage={() => HandleLanguage("en")}
                Redirect={() => {
                  Redirect("/contact");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <FormHooks/> */}
      <div className="Tools__section_ourTools">
        <h2 className="OurTools__title">{t("OurTools_title")}</h2>
        <div className="OurTools__container">
          {
            aboutTools.map((item, index) =>
              <div className="OurTools__container_content" key={index}>
                <img onClick={() => {
                  if (cookies.DataUser) {
                    sendRegister(lang, item.id === 1 ? "CANVAS_INSTRUCTIONS" : "PROCESS_INSTRUCTIONS");
                  } else {
                    setIsOpen(item.id === 1 ? "CANVAS_INSTRUCTIONS" : "PROCESS_INSTRUCTIONS");
                  }
                }}
                  src={item[`content_${lang}`]} alt={item.alt} />
                <p>{item[`text_${lang}`]}</p>
              </div>
            )
          }
        </div>
      </div>
      <div className="Tools__section">
        <h2>{t("title_2")}</h2>
        <div className="ToolsPage__end--container">
          <a
            className="ToolsPage__end--content"
            href={topFive[`href_${lang}`]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="ToolsPage__end--content--img" src={topFive[`content_${lang}`]} alt="Imagen ToolsPlay" />
          </a>
          <a
            className="ToolsPage__end--content"
            href={iconPlay[`href_${lang}`]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="ToolsPage__end--content--img Nomalize_size" src={iconPlay[`content_${lang}`]} alt="Imagen ToolsPlay" />
          </a>
          <a
            className="ToolsPage__end--content"
            href={vblogs[`href_${lang}`]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="ToolsPage__end--content--img" src={vblogs[`content_${lang}`]} alt="Imagen ToolsPlay" />
          </a>
        </div>
        <div className="Tools__menssage--end">{t("title_end")}</div>
      </div>
      <Modal
        isOpen={successful}
        onClose={() => setSuccessful(!successful)}
        closeButton={false}
        background={"white"}
      >
        <div className="Health__button">
          <div className="Health__Button_header">
            <img className="Start" src={ImageStartDer} alt="Start Left" />
            <h3>{t("popUp_title")}</h3>
            <img className="Start" src={ImageStartIzq} alt="Start Right" />
          </div>
          <div className="Health__Button_content">
            {t("popUp_body")}
          </div>
          <div className="Health__Button_content">
            {t("popUp_footer")}
          </div>

          <div className="Button__container_image">
            <img
              className="Button__content_image"
              src={LogoNbD}
              alt="img"
            />
          </div>
          <div className="Health__container_bottom">
            <button
              className="Health__button_form button_enabled"
              onClick={() => setSuccessful(!successful)}
            >
              {t("popUp_button")}
            </button>
          </div>
        </div>
      </Modal>
      <div className="Tools__section">
        <h3 className="section--title">{t('title_3')}</h3>
        <Link className="Tools__button " to="/contact">
          {t('title_button')}
        </Link>
      </div>
    </div>
  );
}

export default translate("tools")(Tools);
