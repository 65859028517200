import React, { useEffect, useRef } from 'react';
import './AboutUs.css';
import { translate } from 'react-translate';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Carrusel from '../../components/Sliders/SliderOurs';

import ImageBanner from '../../assets/images/About/Recurso 1.png';
import ImageBannerEs from '../../assets/images/About/Recurso 2.png';

import Image1 from '../../assets/images/About/ilustrations-03.png';
import Image2 from '../../assets/images/About/ilustrations-04.png';
import Image3 from '../../assets/images/About/ilustrations-05.png';
import Image4 from '../../assets/images/About/ilustrations-06.png';

function AboutUs(props) {
  let { t } = props;
  const lang = localStorage.getItem('Lang');

  const { match: { params } } = props
  const differentiators = useRef();
  const whereWeWork = useRef();
  const ourTeam = useRef();

  useEffect(() => {
    const scroll = (value) => {
      if (value === "differentiators") {
        window.scroll({
          top: differentiators.current.offsetTop - 100,
          left: 0,
          behavior: "smooth",
        });
      } else if (value === "whereWeWork") {
        window.scroll({
          top: whereWeWork.current.offsetTop - 100,
          left: 0,
          behavior: "smooth",
        });
      } else if (value === "ourTeam") {
        window.scroll({
          top: ourTeam.current.offsetTop - 100,
          left: 0,
          behavior: "smooth",
        });
      } else if (value === undefined) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        })
      }
    };
    scroll(params.scroll);
  }, [params.scroll]);

  return (
    <div className="AboutUs__page">
      <Helmet>
        <title>Negotiation by Design | About us</title>
      </Helmet>
      <Header
        ImageBanner={lang === 'en' ? ImageBanner : ImageBannerEs}
        title="title_about"
        paragraph="paragraph_about"
      />
      <div className="AboutUs__page--container">
        <div ref={differentiators} className="Container__differentiators--one">
          <div className="Differentiators__image">
            <img className="Differentiators__image--one" src={Image1} alt="Differentiators One" />
          </div>
          <div className="Differentiators__text">
            <h4>{t('list_title_1')}</h4>
            <p>{t('list_text_1')}</p>
          </div>
        </div>
        <div className="Container__differentiators--two">
          <div className="Differentiators__text">
            <h4>{t('list_title_2')}</h4>
            <p>{t('list_text_2')}</p>
          </div>
          <div className="Differentiators__image">
            <img className="Differentiators__image--two" src={Image2} alt="Differentiators Two" />
          </div>
        </div>
        <div className="Container__differentiators--one">
          <div className="Differentiators__image">
            <img className="Differentiators__image--one" src={Image3} alt="Differentiators Three" />
          </div>
          <div className="Differentiators__text">
            <h4>{t('list_title_3')}</h4>
            <p>{t('list_text_3')}</p>
          </div>
        </div>
        <div className="Container__differentiators--two">
          <div className="Differentiators__text">
            <h4>{t('list_title_4')}</h4>
            <p>{t('list_text_4')}</p>
          </div>
          <div className="Differentiators__image">
            <img className="Differentiators__image--two" src={Image4} alt="Differentiators Four" />
          </div>
        </div>

        <div ref={whereWeWork} className="Container__whereWeWork">
          <h2>{t('title_2')}</h2>
          <h5>{t('text_1')}</h5>
          <img src="https://wisengine-nbd.s3.amazonaws.com/website/nbd-website-where-we-work.gif" alt="Where We Work" />
          <div className="Line__blue"></div>
        </div>

        <div ref={ourTeam} className="AboutUs__ours">
          <h2>{t('title_ours')}</h2>
          <br /><br />
          <Carrusel />
        </div>
      </div>
      <div className="AboutUs__section">
        <h3 className="section--title">{t('title_3')}</h3>
        <Link className="AboutUs__button " to="/contact">
          {t('title_button')}
        </Link>
      </div>
    </div>
  );
}

export default translate('aboutUS')(AboutUs);
