import React from "react";
import { translate } from "react-translate";
import "./ButtonClient.css";

function ButtonClient(props) {
  const { text, btnImg } = props;
  let { t } = props;
  return (
    <React.Fragment>
      <button
        type="button"
        className="ButtonClient--button"
        onClick={() => {
          btnImg(0);
        }}
      >
        {t(text)}
      </button>
    </React.Fragment>
  );
}

export default translate("client")(ButtonClient);
