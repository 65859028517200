import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.css';

/*
* DOCUMENTACIÓN:
* @autor JonathanVargas23
* @date 9/02/2021
*	@parameters {isOpen, onClose, size, children, closeButton, detail, fullScreen, height, background}
*
* size (string): Propiedad diseñada para ajustar el tamaño del modal por css. Valor por defecto "normal"
* -- valores ["normal"]
*	-- para asignar otros valores se debera crear la clase
* -- .Modal__container.{size}
*
* background (string): Propiedad diseñada para ajustar el color de fondo del modal. Valor por defecto white
* -- valores ["white", "gray"]
* -- para asignar otros valores de debera crear la clase
* -- Modal__background_{background}
*
* closeButton (booleano): Propiedad diseñada para el botón cerrar de la parte superior derecha. Valor por defecto true
*/

function Modal({
	isOpen,
	onClose,
	size,
	children,
	closeButton = true,
	detail,
	fullScreen,
	height,
 	background = 'white'
}) {

	if (!isOpen) {
		return null;
	};

	// valores por defecto:
	if (!size || size === null || size === undefined) {
		size = 'normal'
	};

 return (
	 ReactDOM.createPortal(
		<div className={"Modal"}>
			<div
				className="Modal__overlay_close"
				onClick={onClose}
			/>
			<div
				className={`Modal__container ${size} Modal__background_${background}`}
			>
				{
					closeButton &&
					<div className="Modal__close-container">
						<div className="Modal__close-button" onClick={onClose}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								className="Modal__close-icon"
							>
								<path fillRule="evenodd" clipRule="evenodd" d="M13.142 10.0003L19.3484 3.79378C20.2172 2.92492 20.2172 1.52051 19.3484 0.651649C18.4795 -0.217216 17.0752 -0.217216 16.2063 0.651649L10 6.85814L3.79368 0.651649C2.92484 -0.217216 1.52047 -0.217216 0.65163 0.651649C-0.21721 1.52051 -0.21721 2.92492 0.65163 3.79378L6.85795 10.0003L0.65163 16.2068C-0.21721 17.0756 -0.21721 18.48 0.65163 19.3489C1.08494 19.7822 1.6538 20 2.22265 20C2.79151 20 3.36037 19.7822 3.79368 19.3489L10 13.1424L16.2063 19.3489C16.6396 19.7822 17.2085 20 17.7773 20C18.3462 20 18.9151 19.7822 19.3484 19.3489C20.2172 18.48 20.2172 17.0756 19.3484 16.2068L13.142 10.0003Z" fill="#FFFFFF"/>
							</svg>
						</div>
					</div>
				}
				{children}
			</div>
		</div>,
		document.getElementById('modal')
	 )
 );
};

export default Modal;
