import React from 'react';
import { translate } from 'react-translate';
import './Input.css';

function Input(props) {
  const { t } = props;
  const {
    type,
    name,
    value,
    onKeyUp,
    placeholder,
    onChange,
    options,
    // checked,
    lang,
    onClick,
    text,
    multiple,
    className,
    choose,
    confirm,
  } = props;

  if (options) {
    let option;
    for (let i = 0; i < options.length; i++) {
      option = <option>{options[i].name}</option>;
    }
    console.log("option",option);
  }
  return type === 'text' ? (
    <div className="Coponent__input">
      <input
        type={type}
        name={name}
        className="Component__input--text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
    </div>
  ) : type === 'checkbox' ? (
    multiple ? (
      <div className="Coponent__checkbox">
        {options.map((option) => {
          return (
            <div className="Component__input--select-checkbox" key={option.id}>
              <label className="Component__input--container">
                <input
                  type="checkbox"
                  name={option.id}
                  onClick={onClick}
                  value={option.id}
                />
                {option.name}
                <span className="Component__input--container--span"></span>
              </label>
            </div>
          );
        })}
      </div>
    ) : (
        <div className="Form_checkbox">
          <div className="Form__checkbox--round">
            {confirm === 'N' && (
              <input
                type="checkbox"
                id={name}
                name={name}
                value={name}
                onChange={onChange}
              />
            )}
            {confirm === 'Y' && (
              <input
                type="checkbox"
                id={name}
                name={name}
                value={name}
                onChange={onChange}
                checked
              />
            )}
            {/* {confirm === "Y" ? (
            <input
              type="checkbox"
              id={name}
              name={name}
              value={name}
              onChange={onChange}
              checked
            />
          ) : (
            <input
              type="checkbox"
              id={name}
              name={name}
              value={name}
              onChange={onChange}
            />
          )} */}

            {/* {check ? (
            <input
              type="checkbox"
              id={name}
              name={name}
              value={name}
              onClick={onClick}
              checked
            />
          ) : (
            <input
              type="checkbox"
              id={name}
              name={name}
              value={name}
              onClick={onClick}
            />
          )} */}
            <label htmlFor={name}> </label>
          </div>
          <label className="Form__checkbox--text" htmlFor={name}>
            {text}
          </label>
        </div>
      )
  ) : type === 'select' ? (
    <div className="Coponent__input">
      <select
        multiple={multiple}
        className={multiple ? className : 'Component__input--text'}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
      >
        {choose ? (
          <option value="0">{choose}</option>
        ) : (
            <option value="0">{t('choose_one')}</option>
          )}
        {options.length > 0 &&
          options.map((option) => {
            let nameM = `NAME_${lang}`;
            console.log(nameM);
            return (
              <option key={option.ID} value={option.ID}>
                {lang === undefined || lang === null
                  ? option.NAME.toUpperCase()
                  : option[`NAME_${lang.toUpperCase()}`].toUpperCase()}
              </option>
            );
          })}
      </select>
    </div>
  ) : type === 'textarea' ? (
    <textarea
      name={name}
      className="Input__textarea"
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  ) : (
            ''
          );
}

export default translate('input')(Input);
