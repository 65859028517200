import React from 'react';

import Menu from '../Menu/index';

function Layout(props) {
  const style = {
    width: '100%',
  };
  return (
      <div style={style} className="Container__page">
        <Menu />
        {props.children}
      </div>
  );
}

export default Layout;
