import axios from 'axios';
const url = 'https://wisengine.negotiationbydesign.com:8443';
// const url = 'https://test.wisengine.co:8443';
// const url = 'http://localhost:8443';

export function catalogs() {
	return new Promise(async (resolve, reject) => {
		try {
			let response = await axios({
				url: `${url}/website/catalogs`,
				method: 'GET',
			})
			resolve(response.data);
		} catch (e) {
			reject(e.response || e);
		}
	});
};

export function form(data) {
	return new Promise(async (resolve, reject) => {
		try {
			// const body = { name, company, email, country_id, city, form, how_did, product_id, detail, lang }
			//console.log('Servicio form: ', data);

			const body = {
				name: data.name || undefined,
				company: data.company || "NULL",
				email: data.email || undefined,
				country_id: data.country_id || 1,
				city: data.city || "NULL",
				form: data.form || undefined,
				product_id: data.product_id || undefined,
				detail: data.detail || undefined,
				lang: data.lang || undefined,
				last_name: data.last_name || undefined,
				profession: data.profession || undefined,
				position: data.position || undefined,
				origin: data.origin || undefined,
				origin_field: data.origin_field || undefined,
				interest: data.interest || undefined,
				billing: data.billing || undefined,
				tool: data.tool || undefined,
				tool_lang: data.tool_lang || undefined,
				previously: data.previously || undefined,

			};

			let response = await axios({
				url: `${url}/website/form`,
				method: 'POST',
				data: body
			});

			resolve(response.data);
		} catch (e) {
			reject(e.response || e);
		}
	});
};
