import React, { Fragment, useState } from "react";
import Modal from "../../components/Modal";

import { translate } from "react-translate";
import "./Card.css";

function Card(props) {
  const {
    image,
    orange,
    title,
    text,
    button,
    popup,
    popup_title,
    popup_subtitle,
    popup_img,
    popup_text1,
    popup_text2,
    popup_text3,
    popup_text4,
    popup_button,
    name_list,
    list,
    popup_before_img,
    popup_strongtext1,
  } = props;
  const { t } = props;
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    if (!popup) {
      window.location = "/";
    } else {
      setShowModal(true);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const contact = () => {
    window.location = "/contact";
  };
  return (
    <Fragment>
      <div className="Card__content" onClick={handleOpenModal}>
        <div className="Card__content--media" >
          <img src={image} alt="image_card" />
        </div>
        <div className="Card__content--title">
          <p className={orange ? "Card__title--orange" : "Card__title"}>
            {title}
          </p>
          <p className="Card__texto">{text}</p>
          <p className="Card__button" onClick={handleOpenModal}>
            {button}
          </p>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        onClose={handleCloseModal}
        closeButton={true}
        background={"gray"}
      >
        <div className="Health__modal_content">
          <div className="Health__container_form">
            <div className="Health__form_header">
              <div className="Health__form_title">{t(popup_title)}</div>
              <div className="Health__form_subtitle">{t(popup_subtitle)}</div>
            </div>
            <div className="Popup__container">

              {/** valdiadcion para el tercer texto en negro */}
              {popup_before_img &&
                <div className="Card__popup--subtitleblack">
                  <p>{t(popup_before_img)}</p>
                </div>
              }
              <div className="Popup__container_img">
                <img src={popup_img} alt="Icon Card Popup" />
              </div>
              {name_list ? (
                <div className="Popup__content">
                  <div className="Popup__title_list">
                    <h3>{t(name_list)}</h3>
                  </div>
                  <div className="Popup__content_text">
                    <div className="Content__text_column">
                      <ul>
                        {list.map((item) =>
                          item.id < 4 &&
                          <li key={item.id}>{t(item.text)}</li>

                        )}
                      </ul>
                    </div>
                    <div className="Content__text_column">
                      <ul>
                        {list.map((item) =>
                          item.id > 3 &&
                          <li key={item.id}>{t(item.text)}</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : popup_text3 ? (
                <div className="Popup__content_text">
                  <div className="Content__text_column">
                    <p>{t(popup_text1)}</p>
                    <p>{t(popup_text2)}</p>
                  </div>
                  <div className="Content__text_column">
                    <p>{t(popup_text3)}</p>
                    <p>{t(popup_text4)}</p>
                  </div>
                </div>
              ) : popup_strongtext1 ? (
                <div className="Card__popup--text">
                  <p
                    dangerouslySetInnerHTML={{ __html: t(popup_strongtext1) }}
                  ></p>
                </div>
              ) : (
                <div className="Popup__content_text">
                  <p>{t(popup_text1)}</p>
                  <p>{t(popup_text2)}</p>
                </div>
              )}
              <div className="Popup__button">
                <button className="Popup__button_btn" onClick={contact}>{t(popup_button)}</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>


    </Fragment>
  );
}

export default translate("card")(Card);
