import React, { useState, useEffect } from 'react';

import { translate } from 'react-translate';
import { form, catalogs } from '../../api-akeportal';
import { Helmet } from 'react-helmet';

import Header from '../../components/Header';
import Input from '../../components/Input';
import Loader from '../../components/Loader';
import UpdateInput from "../../components/UpdateInput";
import Modal from "../../components/Modal";

import './Contact.css';
import ImageBanner from '../../assets/images/banner/Imagen-banner-contact.png';
import LogoNbD from "../../assets/images/Health/logo-nbd.svg";
import ImageStartIzq from "../../assets/images/Health/StartIzq.svg";
import ImageStartDer from "../../assets/images/Health/StartDer.svg";

function Contact(props) {
  let { t } = props;
  const { match: { params } } = props
  const lang = localStorage.getItem("Lang");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [successful, setSuccessful] = useState(false);
  const [fieldEmpty, setFieldEmpty] = useState([]);
  const [register, setRegister] = useState({
    name: { value: "", error: false },
    last_name: { value: "", error: false },
    email: { value: "", error: false },
    detail: { value: "", error: false }
  });
  const [data, setData] = useState({
    name: '',
    company: '',
    email: '',
    product: 0,
    country_id: 0,
    city: '',
    detail: '',
    term: 'N',
  });
  //eslint-disable-next-line    
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  useEffect(() => {
    const scroll = (value) => {
      if (value === undefined) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    };
    scroll(params.scroll);
  }, [params.scroll]);

  
 
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    // Start loading
    setLoading(true)
    let data = await catalogs();
    setLoading(false)
    // console.log(data);
    // console.log(data.countrys);
    // console.log(data.products);
    //setCountry(data.countrys);
    //let countryUpper = [];
    //for (let i = 0; i < data.countrys.length; i++) {
    //  countryUpper[i] = data.countrys[i];
    //}
    //console.log(countryUpper);
    //setProduct(data.products);
    // End loading
    // console.log('End loading');
  };

  const handleChange = (e, field) => {
    // Actualizar localmente en la función la neva data del perfil fisico.
    let dataRegister = {
      ...register,
      [field]: {
        ...register[field],
        value: e.target.value,
        error: false,
      },
    };

    // validación de campos vacios
    if (
      dataRegister['name'].value + '' === '' ||
      dataRegister['last_name'].value + '' === '' ||
      dataRegister['email'].value + '' === '' 
    ) {
      setFieldEmpty(true);
    }
    else {
      setFieldEmpty(false);
    };
    setRegister(dataRegister);
  };
  const cleanRegister = () => {
    setRegister({
      name: { value: "", error: false },
      last_name: { value: "", error: false },
      email: { value: "", error: false },
      detail: { value: "", error: false }
    });
  };

  // Funcion para validar los campos
  const validateEmpy = async (fields) => {
    let newData = register;
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i];
      let validate = newData[field].value + ''.trim();
      if (validate.length === 0) {
        newData = {
          ...newData,
          [field]: {
            ...newData[field],
            error: true,
          },
        };
      } else if (field === "email") {
        if (!re.test(validate)) {
          newData = {
            ...newData,
            [field]: {
              ...newData[field],
              error: true,
            },
          };
        };
      }
    };
    if (register === newData) {
      return true;
    } else {
      setRegister(newData);
      return false
    };
  };
  const sendRegister = async () => {
    // console.log('Envío de formulario: ', register);
    let validate = await validateEmpy([
      'name',
      'last_name',
      'email',
    ]);
    if (!validate) return;
    // if (data.term === 'N') {
    //   setError(true)
    //   return
    // }
    /* Validación de comillas: */
    let insertName = register["name"].value.replace(/["']/g, "");
    let insertLastname = register["last_name"].value.replace(/["']/g, "");
    let insertDetail = register["detail"].value.replace(/["']/g, "");

    let dataForm = {
      'form': 'CONTACT',
      'lang': lang,
      'name': insertName,
      'last_name': insertLastname,
      'email': register['email'].value,
      'detail': insertDetail
    };
    // console.log('Formulario final para enviar:', dataForm);

    try {
      // console.log('llamado del servicio:');
      await form(dataForm);
      setSuccessful(true);
      cleanRegister();
      // setData({
      //   term: 'N',
      // });

    } catch (error) {
      console.log('error en el guardado', error);
    }
  }

  // const ConfirmChecked = () => {
  //   if (data.term === 'N') {
  //     setData({
  //       ...data,
  //       term: 'Y',
  //     });
  //     setError(false);
  //   } else {
  //     setData({
  //       ...data,
  //       term: 'N',
  //     });
  //     setError(true);
  //   };
  // };

  return (
    <>
      <div className="Contact__page">
        <Helmet>
          <title>Negotiation by Design | Contact</title>
        </Helmet>
        <Header title="title_contact" ImageBanner={ImageBanner} />
        <div className="Contact__page--container">
          <Loader Style="modal" state={loading} />
          <div className="Container__form">
            <div className="Container__form_input">

              <UpdateInput
                title={t('names') + ' *'}
                type="text"
                setPlacerholder={t('names')}
                value={register['name'].value}
                alert={register['name'].error}
                onChange={(e) => { handleChange(e, 'name') }}
              />
            </div>
            <div className="Container__form_input">
              <UpdateInput
                title={t('lastnames') + ' *'}
                type="text"
                setPlacerholder={t('lastnames')}
                value={register['last_name'].value}
                alert={register['last_name'].error}
                onChange={(e) => { handleChange(e, 'last_name') }}
              />
            </div>
            <div className="Container__form_input">
              <UpdateInput
                title={t('email') + ' *'}
                type="text"
                setPlacerholder={t('email')}
                value={register['email'].value}
                alert={register['email'].error}
                onChange={(e) => { handleChange(e, 'email') }}
              />
            </div>
            <div className="Container__form_input_textarea">
              <UpdateInput
                title={t('addDetails')}
                type="textarea"
                setPlacerholder={t('addDetails')}
                value={register['detail'].value}
                alert={register['detail'].error}
                onChange={(e) => { handleChange(e, 'detail') }}
              />
            </div>
          </div>
          <div className="Container__form_send">
            <div className="Container__form_input_checkbox">
              <Input
                type="checkbox"
                id="checkbox"
                name="term"
                text={t('text_checkbox')}
                confirm={data.term}
                //onChange={(e) => ConfirmChecked()}
              />
            </div>

            {/* <p>{error && t('accept')}</p> */}
            <div className="Container__form_buttom">
              <button
                className="Health__button_form button_enabled"
                // className={
                //   !fieldEmpty && data.term === 'Y'
                //     ? 'Health__button_form button_enabled'
                //     : 'Health__button_form button_disabled'
                // }
                onClick={sendRegister}
              >
                {t('button_send')}
              </button>
            </div>
          </div>


          {/* <Input
              type="text"
              name="name"
              onKeyUp={hadlekeyup}
              value={data.name}
              onChange={(e) =>
                setData({
                  ...data,
                  name: e.target.value.toUpperCase(),
                })
              }
              placeholder={t('name')}
            />
            {errors.name && <p>{errors.name}</p>}
          </div>
          <div className="Form__group">
            <label htmlFor="name"> {t('companysName')} </label>
            <Input
              type="text"
              name="company"
              onKeyUp={hadlekeyup}
              value={data.company}
              onChange={(e) =>
                setData({
                  ...data,
                  company: e.target.value.toUpperCase(),
                })
              }
              placeholder={t('placeolder_company')}
            />
            {errors.company && <p>{errors.company}</p>}
          </div>
        </div>
        <div className="Container__form">
          <div className="Form__group">
            <label htmlFor="name"> {t('email')} </label>
            <Input
              type="text"
              name="email"
              onKeyUp={hadlekeyup}
              value={data.email}
              onChange={(e) =>
                setData({
                  ...data,
                  email: e.target.value.toUpperCase(),
                })
              }
              placeholder={t('placeolder_email')}
            />
            {errors.email && <p>{errors.email}</p>}
          </div> */}

          {/* <div className="Form__group">
            <label htmlFor="name"> {t('product')} </label>
            <div className="Coponent__input">
              <select
                className={'Component__input--text'}
                value={data.product}
                onChange={(e) =>
                  setData({
                    ...data,
                    product: e.target.value.toUpperCase(),
                  })
                }
                name="product"
              >
                <option value="0">{t('choose_one')}</option>
                {product.length > 0 &&
                  product.map((option) => {
                    return (
                      <option key={option.ID} value={option.ID}>
                        {lang === undefined
                          ? option.name.toUpperCase()
                          : option[`NAME_${lang.toUpperCase()}`].toUpperCase()}
                      </option>
                    );
                  })}
              </select>
            </div>
            {errors.product && <p>{errors.product}</p>}
          </div>
        </div> */}
          {/* <div className="Container__form">
          <div className="Form__group">
            <label htmlFor="name"> {t('country')} </label>
            <div className="Coponent__input">
              <select
                className={'Component__input--text'}
                value={data.country_id}
                onChange={(e) =>
                  setData({
                    ...data,
                    country_id: e.target.value.toUpperCase(),
                  })
                }
                name="country_id"
              >
                <option value="0">{t('choose_one')}</option>
                {countrys.length > 0 &&
                  countrys.map((option) => {
                    return (
                      <option key={option.ID} value={option.ID}>
                        {lang === undefined
                          ? option.name.toUpperCase()
                          : option[`NAME_${lang.toUpperCase()}`].toUpperCase()}
                      </option>
                    );
                  })}
              </select>
            </div>
            {errors.country_id && <p>{errors.country_id}</p>} */}
          {/* <Input
              type="select"
              name="country_id"
              onChange={(e) =>
                setData({
                  ...data,
                  country_id: e.target.value.toUpperCase(),
                })
              }
              options={country}
              lang={lang}
              placeholder={t("placeolder_country")}
            /> */}
          {/* {errors.country_id && <p>{errors.country_id}</p>} */}
          {/* </div>
          <div className="Form__group">
            <label htmlFor="name"> {t('city')} </label>
            <Input
              type="text"
              name="city"
              onKeyUp={hadlekeyup}
              value={data.city}
              onChange={(e) =>
                setData({
                  ...data,
                  city: e.target.value.toUpperCase(),
                })
              }
              placeholder={t('placeolder_city')}
            />
            {errors.city && <p>{errors.city}</p>}
          </div>
        </div>
        <div className="Form__group">
          <label> {t('addDetails')} </label> */}
          {/* ={e => setFirstName(e.target.value, 10)} */}
          {/* <Input
            type="textarea"
            placeholder={t('placeolder_details')}
            name="comment"
            value={data.comment}
            onChange={(e) => {
              ValidaLonght(e.target.value, 961);

              setData({
                ...data,
                comment: e.target.value,
              });
            }}
          />
          {errors.comment && <p>{errors.comment}</p>}
        </div>
        <Input
          type="checkbox"
          id="checkbox"
          name="term"
          text={t('text_checkbox')}
          confirm={data.term}
          onChange={(e) => ConfirmChecked()}
        />
        {errors.term && <p>{errors.term}</p>}
        <div className="Form__group--button">
          <button onClick={(e) => saveContent()}>{t('button_send')}</button>
        </div> */}
        </div>
      </div>
      <Modal
        isOpen={successful}
        onClose={() => setSuccessful(!successful)}
        closeButton={false}
        background={"white"}
      >
        <div className="Health__button">
          <div className="Health__Button_header">
            <img className="Start" src={ImageStartDer} alt="Start Left" />
            <h3>{t("popUp_title")}</h3>
            <img className="Start" src={ImageStartIzq} alt="Start Right" />
          </div>
          <div className="Health__Button_content">
            {t("popUp_body")}
          </div>
          <div className="Health__Button_content">
            {t("popUp_footer")}
          </div>

          <div className="Button__container_image">
            <img
              className="Button__content_image"
              src={LogoNbD}
              alt="img"
            />
          </div>
          <div className="Health__container_bottom">
            <button
              className="Health__button_form button_enabled"
              onClick={() => setSuccessful(!successful)}
            >
              {t("popUp_button")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default translate('contact')(Contact);
