import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ProfileJean from '../../../assets/images/Clients/jean-d-g.jpg';
import ProfileMichelle from '../../../assets/images/Clients/Buck_Michelle_2012-1.jpg';
import ProfileTracey from '../../../assets/images/Clients/0c23ea9.jpg';
import ProfileRone from '../../../assets/images/Clients/Rone.jpg';
import ProfileKevin from '../../../assets/images/Clients/kevin-kim.jpeg';
import ProfileAnil from '../../../assets/images/Clients/Anil-Kumar.jpg';

import './SliderClients.css';

function SliderClients() {
  const lang = localStorage.getItem("Lang");
  var settings = {
    // vertical:true,
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 1,
    className: "slides",
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const dataClients = [
    {
      id: 1,
      name: "Jean-Daniel Gervais",
      cargo_es: "Vicepresidente de ventas, Keurig Canada",
      description_es: "“El Canvas de Negociación nos permite identificar más valor para ambas partes, hacer mejores tratos y construir relaciones más sólidas con los minoristas más grandes del mundo”.",
      profileUrl: ProfileJean,
      cargo_en: "Vice President of Sales, Keurig Canada",
      description_en: "“The Negotiation Canvas allows us to identify more value for both parties, make better deals and build stronger relationships with the world’s biggest retailers.”",
    },
    {
      id: 2,
      name: "Michelle L. Buck",
      cargo_es: "Profesora clínica, Kellogg School of Management",
      description_es: "“Negotiation by Design destila la compleja teoría de la negociación en herramientas prácticas y poderosas. ¡Una manera brillante de asegurarse de que lo que se enseña en clase se use en la oficina! ”",
      profileUrl: ProfileMichelle,
      cargo_en: "Clinical Professor, Kellogg School of Management",
      description_en: "“Negotiation by Design distills complex negotiation theory into practical, powerful tools. A brilliant way to make sure what is taught in class is used back in the office!”",
    },
    {
      id: 3,
      name: "Tracey York",
      cargo_es: "Vicepresidente de gestión global de talento, Berry Global",
      description_es: "“El Canvas de Negociación funciona en todas las culturas y le da a nuestro equipo una forma consistente de desempeñarse mejor en todas nuestras negociaciones”.",
      profileUrl: ProfileTracey,
      cargo_en: "VP, Global Talent Management, Berry Corporation",
      description_en: "“The Negotiation Canvas works across cultures and gives our team one consistent way to perform better on all our negotiations.”",
    },
    {
      id: 4,
      name: "Ronen Tal",
      cargo_es: "Comprador de vivienda, Toronto, Canada",
      description_es: "“Utilizando las técnicas de la clase del profesor Restrepo, pudimos comprar la casa de nuestros sueños dentro de nuestro presupuesto en un mercado inmobiliario realmente difícil”.",
      profileUrl: ProfileRone,
      cargo_en: "Home buyer, Toronto, Canada",
      description_en: "“Using the techniques from Prof. Restrepo´s class, we were able to buy our dream home well within our budget in a really tough housing market.”",
    },
    {
      id: 5,
      name: "Kevin Jaeho Kim",
      cargo_es: "Director, Adidas",
      description_es: "“Cuando tomé la decisión de tomar el curso de negociación del profesor Pablo Restrepo, no me di cuenta de que estaba haciendo una elección que influiría en cada momento de mi vida. El curso fue más que solo aprender tácticas de negociación ”.",
      profileUrl: ProfileKevin,
      cargo_en: "Director, Adidas",
      description_en: "“When I made the decision to take a negotiation course from Professor Pablo Restrepo, I did not realize I was making a choice that would influence every moment of my life. The course was more than just learning negotiation tactics.”",
    },
    {
      id: 6,
      name: "Anil Kumar",
      cargo_es: "SVP, Automatización e innovación, Citi",
      description_es: "“En mi rol de productividad e innovación, la negociación ocurre en cada paso y me alegra tener el Canvas de Negociación para ayudarme a identificar más valor y expandir el pastel”.",
      profileUrl: ProfileAnil,
      cargo_en: "SVP, Automation & Innovation, Citi",
      description_en: "“In my productivity and innovation role, negotiation happens at every step and I am glad to have the Negotiation Canvas to help me identify more value and expand the pie.”",
      "title_4": " Have questions about our workshops?",
    },
  ]
  return (
    <Slider {...settings}>
      {
        dataClients.map((item, index) =>
          <div className="Container__slider" key={index}>
            <div className="LongSlider__card">
              <div className="LongSlider__card--image">
                <img src={item.profileUrl} alt="Icon Clients" />
              </div>
              <div className="LongSlider__card--text">
                <h5>{item.name}</h5>
                <span>{item[`cargo_${lang}`]}</span>
                <p>{item[`description_${lang}`]}</p>
              </div>
            </div>
          </div>
        )
      }
    </Slider>
  );
}
export default SliderClients;
