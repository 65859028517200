import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Corporate_1 from "../../../../assets/images/Corporate/Corporate_clients_Mesa_de_trabajo_1.png";
import Corporate_2 from "../../../../assets/images/Corporate/Corporate_clients-02.png";
import Corporate_3 from "../../../../assets/images/Corporate/Corporate_clients-03.png";
import Corporate_4 from "../../../../assets/images/Corporate/Corporate_clients-04.png";
import Corporate_5 from "../../../../assets/images/Corporate/Corporate_clients-05.png";
import Corporate_6 from "../../../../assets/images/Corporate/Corporate_clients-06.png";
import Corporate_7 from "../../../../assets/images/Corporate/Corporate_clients-07.png";
import Corporate_8 from "../../../../assets/images/Corporate/Corporate_clients-08.png";
import Corporate_9 from "../../../../assets/images/Corporate/Corporate_clients-09.png";
import Corporate_10 from "../../../../assets/images/Corporate/Corporate_clients-10.png";
import Corporate_11 from "../../../../assets/images/Corporate/Corporate_clients-11.png";
import Corporate_12 from "../../../../assets/images/Corporate/Corporate_clients-12.png";
import Corporate_13 from "../../../../assets/images/Corporate/Corporate_clients-13.png";
import Corporate_14 from "../../../../assets/images/Corporate/Corporate_clients-14.png";
import Corporate_15 from "../../../../assets/images/Corporate/Corporate_clients-15.png";
import Corporate_16 from "../../../../assets/images/Corporate/Corporate_clients-16.png";
import Corporate_17 from "../../../../assets/images/Corporate/Corporate_clients-17.png";
import Corporate_18 from "../../../../assets/images/Corporate/Corporate_clients-18.png";
import Corporate_19 from "../../../../assets/images/Corporate/Corporate_clients-19.png";
import Corporate_20 from "../../../../assets/images/Corporate/Corporate_clients-20.png";
import Corporate_21 from "../../../../assets/images/Corporate/Corporate_clients-21.png";
import Corporate_22 from "../../../../assets/images/Corporate/Corporate_clients-22.png";
import Corporate_23 from "../../../../assets/images/Corporate/Corporate_clients-23.png";
import Corporate_24 from "../../../../assets/images/Corporate/Corporate_clients-24.png";
import Corporate_25 from "../../../../assets/images/Corporate/Corporate_clients-25.png";

import "../SliderLogo.css";

function Corporate() {
  var settings = {
    vertical: true,
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 2,
    className: "slidesClients",
    autoplay: false,
  };
  return (
    <Slider {...settings}>
      <div className="Container__slider">
        <div className="Container__item">
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_1} />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_2} />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_3} />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_4} />
          </div>
        </div>
      </div>
      <div className="Container__slider">
        <div className="Container__item">
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_5} />
          </div>

          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_6} />
          </div>

          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_7} />
          </div>

          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_8} />
          </div>
        </div>
      </div>
      <div className="Container__slider">
        <div className="Container__item">
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_9} />
          </div>

          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_10} />
          </div>

          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_11} />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_12} />
          </div>
        </div>
      </div>
      <div className="Container__slider">
        <div className="Container__item">
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_13} className="Corporate__container--img" />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_14} />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_15} />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_16} className="Corporate__container--img" />
          </div>
        </div>
      </div>
      <div className="Container__slider">
        <div className="Container__item">
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_17} className="Corporate__container--img" />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_18} className="Corporate__container--img" />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_19} />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_20} />
          </div>
        </div>
      </div>
      <div className="Container__slider">
        <div className="Container__item">
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_21} />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_22} className="Corporate__container--img" />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_23} className="Corporate__container--img" />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_24} className="Corporate__container--img" />
          </div>
          <div className="Corporate__container">
            <img alt="Icons Sliders Corporate" src={Corporate_25} className="Corporate__container--img" />
          </div>
        </div>
      </div>
    </Slider>
  );
}
export default Corporate;
