import React from "react";
import { translate } from "react-translate";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import dataOurs from "../../../components/ObjectData/dataOurs";

import "./SliderOurs.css";

function SliderOurs() {
  const lang = localStorage.getItem('Lang');
  var settings = {
    // vertical:true,
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 1,
    className: "slides",
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Slider  {...settings}>
      {
        dataOurs.length > 0 ?
          dataOurs.sort((a, b) =>
            (a.orderView < b.orderView && -1)
          ).map((item, index) =>
            <div key={index} className="Container__slider">
              <div className="Card__container">
                <div className="Card__container_image">
                  <img className="Card__image" src={item.url__profile} alt="ProfilePicture" />
                </div>
                <div className="Card__text">
                  <h5>{item.name}</h5>
                  <span>{lang === "en" ? item.positionEn : item.positionEs}</span>
                  <div className="Line"></div>
                  <p>{lang === "en" ? item.descriptionEn : item.descriptionEs}</p>
                  {
                    item.url__linkedin !== "" &&
                    <a
                      className="SocialMedia_icon"
                      href={item.url__linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="https://wisengine-nbd.s3.amazonaws.com/website/icon/website_icon_linkedin.png" alt="Linkedin icon" />
                    </a>
                  }

                </div>
              </div>
            </div>
          )
          :
          <h3>Nose encontraron resultados</h3>
      }
    </Slider>
  );
}

export default translate("SliderOurs")(SliderOurs);
