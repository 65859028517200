import React from "react";

import Ngo_1 from "../../../../assets/images/Ngo/NGO-11.png";
import Ngo_2 from "../../../../assets/images/Ngo/NGO-12.png";
import Ngo_3 from "../../../../assets/images/Ngo/NGO-13.png";

function Ngo() {

  return (
    <React.Fragment>
      <div className="Container__item">
        <div className="Corporate__container">
          <img src={Ngo_1} alt="Icon NGO One" />
        </div>
        <div className="Corporate__container">
          <img src={Ngo_2} alt="Icon NGO Two" />
        </div>
        <div className="Corporate__container">
          <img src={Ngo_3} alt="Icon NGO Three" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Ngo;
