import React, { Fragment } from 'react';
import './ModalTools.css';
function ModalTools(props) {
  const { image, orange, title, Redirect, onClick } = props;

  const handleContact = () => {
    if (Redirect) {
      window.location = '/contact';
    }
  };

  return (
    <Fragment>
      <div className="Card__content" onClick={Redirect ? handleContact : onClick}>
        <div className="Card__content--media">
          <img src={image} alt="image_card" />
        </div>
        <div className="Card__content--title">
          <p className={orange ? 'Card__title--orange' : 'Card__title'}>
            {title}
          </p>
        </div>
      </div>
    </Fragment>
  );
}

export default ModalTools;
