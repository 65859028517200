import React from "react";
import { NavLink } from "react-router-dom";
import { translate } from "react-translate";

function MenuList(props) {
  let { t } = props;

  return (
    <NavLink
      to={props.route}
      className="Menu__item"
      activeClassName="Item__selected"
      open="close"
      onClick={props.onClick}
    >
      {t(props.name)}
    </NavLink>

  );
}
export default translate("menu")(MenuList);
