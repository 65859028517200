const dataOurs = [
  {
    id: 1,
    orderView: 1,
    name: "Pablo Restrepo",
    positionEn: "CEO & Founder",
    positionEs: "CEO y fundador",
    descriptionEn: "Pablo has 20+ years of experience as an international consultant and professor in business strategy, innovation and negotiation in both the private and public sectors. Pablo has been a a teacher and facilitator at McGill University, Kellogg School of Management(Kellogg Innovation Network), Universidad de los Andes and at Columbia University’s United Nations Program for cooperation and conflict resolution. Pablo has and MBA from Universidad de los Andes;and an EMBA from Kellogg School of Management. Pablo is fluent in Spanish, English and French.",
    descriptionEs: "Pablo tiene más de 20 años de experiencia como consultor internacional y profesor de estrategia empresarial, innovación y negociación tanto en el sector público como en el privado. Pablo ha sido profesor y facilitador en la Universidad McGill, en la Escuela de Administración Kellogg (Red de Innovación Kellogg), en la Universidad de los Andes y en el Programa de las Naciones Unidas para la cooperación y la resolución de conflictos de la Universidad de Columbia. Pablo tiene un MBA de la Universidad de los Andes; y un EMBA de la Kellogg School of Management. Pablo habla con fluidez el español, el inglés y el francés.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_PabloRestrepo.jpg",
    url__linkedin: "https://www.linkedin.com/in/pablorestrepo/"
  },
  {
    id: 2,
    orderView: 2,
    name: "Humberto Cárdenas",
    positionEn: "Knowledge Management Director",
    positionEs: "Director de Gestion del Conocimiento",
    descriptionEn: "Humberto is a Systems Engineer from Universidad de Los Andes, where he has been a teacher for more than 25 years in different areas of business management knowledge. He develops and applies state-of-the-art management technologies within highly competitive environments. He has developed his own productivity tools, which complement those offered by specialized organizations in this field, generating a productive synergy for his projects.",
    descriptionEs: "Humberto es Ingeniero de Sistemas de la Universidad de los Andes, donde ha sido docente por más de 25 años en diferentes áreas del conocimiento de la gestión de negocios. Desarrolla y aplica tecnologías en gestión, de última generación, dentro de entornos altamente competitivos y con reglas de juego en permanente evolución. Ha desarrollado sus propias herramientas de productividad, que complementan las ofrecidas por organizaciones especializadas en este campo, generando una sinergia productiva para sus proyectos.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_HumbertoCardenas.png",
    url__linkedin: "https://www.linkedin.com/in/humberto-c%C3%A1rdenas-parra-00ba6215/"
  },
  {
    id: 3,
    orderView: 4,
    name: "Roberto  Ordoñez",
    positionEn: "Middle east director",
    positionEs: "Director de medio oriente",
    descriptionEn: "Roberto is an influencer and change agent with more than 20 years of expertise across the areas of Operations, Finance, Strategy, Planning, Business Development, and Procurement.  He is a Board member of the Kellogg School of Management GCC Alumni association, and member of a GCC (Gulf Cooperation Council) Taskforce to set up innovation ecosystems to promote entrepreneurship in the Gulf region.  Roberto has a Masters in Science from Texas A&M University and an MBA from Northwestern’s Kellogg School of Management.",
    descriptionEs: "Roberto es un influyente y agente de cambio con más de 20 años de experiencia en las áreas de Operaciones, Finanzas, Estrategia, Planificación, Desarrollo de Negocios y Adquisiciones.  Es miembro de la Junta de la asociación de ex alumnos de la Escuela de Administración Kellogg del CCG y miembro de un grupo de trabajo del CCG (Consejo de Cooperación del Golfo) para establecer ecosistemas de innovación para promover el espíritu empresarial en la región del Golfo.  Roberto tiene una maestría en ciencias de la Universidad de Texas A&M y una maestría en administración de empresas de la Escuela de Administración Kellogg de Northwestern.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_RobertoOrdonez.jpg",
    url__linkedin: "https://www.linkedin.com/in/roberto-ordonez-14297275/"
  },
  {
    id: 4,
    orderView: 3,
    name: "Hugo Mcintosh",
    positionEn: "South america director",
    positionEs: "Director de Sudamérica",
    descriptionEn: "Hugo McIntosh is a highly-skilled sales professional with ten years as Vice President of Sales – South America for Berry Global, a Fortune 500 industrial company. Hugo has a deep understanding and real-life experience of what it takes to negotiate successful, long-term relationships with some of the largest companies in the world. He has a proven track record in building effective teams, business development, negotiation and general management. Hugo has a Masters in Business and Commerce from Universidad de San Andres and a Bachelors in Accounting and Finance from Universidad Argentina de la Empresa. Hugo can conduct workshops in Spanish and English.",
    descriptionEs: "Hugo McIntosh es un profesional de las ventas altamente cualificado con diez años como Vicepresidente de Ventas - América del Sur para Berry Global, una empresa industrial de Fortune 500. Hugo tiene un profundo conocimiento y experiencia en la vida real de lo que se necesita para negociar relaciones exitosas y de largo plazo con algunas de las compañías más grandes del mundo. Tiene una trayectoria comprobada en la creación de equipos eficaces, el desarrollo de negocios, la negociación y la gestión general. Hugo tiene una Maestría en Negocios y Comercio de la Universidad de San Andrés y una Licenciatura en Contabilidad y Finanzas de la Universidad Argentina de la Empresa. Hugo puede dirigir talleres en español e inglés.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_HugoMcintosh.jpg",
    url__linkedin: "https://www.linkedin.com/in/hugo-mcintosh-6b18651a/"
  },
  {
    id: 5,
    orderView: 10,
    name: "Ramón Abel Castaño",
    positionEn: "Health systems consultant",
    positionEs: "Consultor en sistemas de salud",
    descriptionEn: "Health systems expert with more than 20 years of experience in Colombia and Latin America. He focuses his work especially on the development of innovations in care and contracting models, and on the development of centres of excellence.  He holds a master's degree in health management and policy from the Harvard School of Public Health, and a PhD in public health and policy from the London School of Hygiene and Tropical Medicine.",
    descriptionEs: "Experto en sistemas de salud y cuenta con más de 20 años de experiencia en Colombia y Latinoamérica. Enfoca su trabajo especialmente en el desarrollo de innovaciones en modelos de atención y de contratación, y en el desarrollo de centros de excelencia.  Es médico con master en gestión y política en salud de Harvard School of Public Health, y tiene un PhD en política y salud pública de la London School of Hygiene and Tropical Medicine.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_RamonAbel.jpg",
    url__linkedin: "https://www.linkedin.com/in/ram%C3%B3n-abel-casta%C3%B1o-164825187/"
  },
  {
    id: 6,
    orderView: 12,
    name: "Martha Santos",
    positionEn: "financial manager",
    positionEs: "Directora Administrativa",
    descriptionEn: "For over 12 years Martha has worked as administrative and financial manager of consulting companies. She has a bachelor in phycology and has trained herself in finance management for non financials at Universidad De Los Andes, startup management at Universidad Javeriana and as financial manager at Universidad de la Sabana. Previews to Aluna Catalyst Martha worked at Tandem insourcing, Nilsen Colombia, Cavipetrol, Suramericana between others.",
    descriptionEs: "Durante más de 12 años Martha ha trabajado como gerente administrativa y financiera de empresas de consultoría. Es licenciada en psicología y se ha formado en gestión financiera para no financieros en la Universidad de los Andes, en gestión de startups en la Universidad Javeriana y como gerente financiera en la Universidad de la Sabana. Martha trabajó en Tandem insourcing, Nilsen Colombia, Cavipetrol, Suramericana entre otros.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture2_MarthaSantos.png",
    url__linkedin: "https://www.linkedin.com/in/martha-santos-g%C3%B3mez-1abb0736/"
  },
  {
    id: 7,
    orderView: 13,
    name: "Daniela Ladino",
    positionEn: "Junior  Consultant",
    positionEs: "Consultora Junior",
    descriptionEn: "Daniela is an Economist with emphasis on Politics and International Relations. She has a real interest on Negotiation, Strategic Planning and Social Responsibility. Previous to Aluna Catalyst, she worked as consultant for a Negotiation firm based in Bogotá, and she focused on labor and procurement negotiation. She is fluent in Spanish and English.",
    descriptionEs: "Daniela es una economista con énfasis en la política y las relaciones internacionales. Tiene un interés real en la negociación, la planificación estratégica y la responsabilidad social. Antes de Aluna Catalyst, trabajó como consultora para una empresa de negociación con sede en Bogotá, y se centró en la negociación laboral y de compras. Habla con fluidez el español y el inglés.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_DanielaLadino.jpg",
    url__linkedin: "https://www.linkedin.com/in/daniela-ladino-58443bb2/"
  },
  {
    id: 9,
    orderView: 14,
    name: "Annie Amaya",
    positionEn: "Junior  Consultant",
    positionEs: "Consultora Junior",
    descriptionEn: "Designer and architect from the Universidad de los Andes with an emphasis in product design. She’s passionate about design methodologies based on research-creation processes and collaborative design. She believes that integrating multiple disciplines and formulating horizontal dialogues between people, companies and communities open great possibilities for establishing exchanges of value and forging paths of collaboration towards processes of appropriation and transformation. She believes that the tools offered by Design Thinking convert process challenges into innovative ideas and solutions. She is fluent in Spanish, Italian and English.",
    descriptionEs: "Diseñadora y arquitecta de la Universidad de los Andes con énfasis en diseño de producto. Apasionada por las metodologías de diseño basadas en procesos de investigación-creación y el diseño colaborativo. Considera que integrar múltiples disciplinas y formular diálogos horizontales entre personas, empresas y comunidades abren grandes posibilidades de establecer intercambios de valor y forjar caminos de colaboración hacia procesos de apropiación y transformación. Considera que las herramientas ofrecidas por el Design Thinking convierten los retos de los procesos en ideas y soluciones innovadoras. Habla con fluidez español, italiano e inglés.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_AnnieAmaya.jpg",
    url__linkedin: ""
  },
  {
    id: 12,
    orderView: 8,
    name: "Enrique Arroyo",
    positionEn: "Senior consultant",
    positionEs: "Consultor senior",
    descriptionEn: "He is a consultant and negotiation facilitator of large corporations and teams in Latin America. He has experience as a professor at the Faculty of Administration of the Universidad de los Andes, Universidad Externado de Colombia, CESA and Universidad Internacional del Ecuador, and as a professional in marketing, operations, and technology. He was a partner of a Colombian consulting firm specialized in negotiation and high-performance teams building. He is passionate about the confluence of technology and knowledge to improve from simple processes to complex decision making. He completed his studies in business administration at ADV, in Lima and his specialization in negotiation in the internal program of Bellsouth, Ecuador, a company in which he had the title of Marketing Director.",
    descriptionEs: "Enrique es consultor y facilitador en negociación de grandes corporaciones y equipos en Latinoamérica. Tiene experiencia como profesor de negociación en la facultad de administración de la Universidad de Los Andes, de la Universidad Externado de Colombia, del CESA y de la Universidad Internacional del Ecuador, y como profesional en marketing, operaciones y tecnología. Fue socio de una firma de consultoría colombiana especializada de negociación y creación de equipos de alto desempeño. Es un apasionado por la confluencia de la tecnología y el conocimiento para mejorar desde procesos simples hasta toma de decisiones complejas. Realizó sus estudios en administración de negocios en ADV, en Lima y su especialización en negociación en el programa interno de BellSouth, Ecuador, empresa en la cual se desempeñó como director de mercadeo.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_EnriqueArroyo.jpg",
    url__linkedin: ""
  },
  {
    id: 13,
    orderView: 9,
    name: "Claudio Peña",
    positionEn: "Senior consultant",
    positionEs: "Consultor senior",
    descriptionEn: "He has dedicated himself to support processes of strategic formulation, development of innovation capacities, cultural transformation, change management, and development of leaders and high-performance teams with an emphasis on senior management, both in national and multinational public and private companies in Colombia and Latin America. He has been project director and from the health area of the SER Research Institute, partner and project director of the Management and Technology Group, and the chief operating officer of Agrolíbano in Honduras. He has a degree in Systems Engineering, a Specialization in Human Resources Management, and an Executive MBA from the Universidad de Los Andes. He is a certified coach in Action Reflection Learning by LIM Ltd. (Leadership in International Management).",
    descriptionEs: "Se ha dedicado a acompañar procesos de formulación estratégica, desarrollo de capacidades de innovación, transformación cultural, gestión del cambio y desarrollo de líderes y equipos de alto desempeño con énfasis en la alta gerencia, tanto en empresas públicas como privadas nacionales y multinacionales en Colombia y Latinoamérica. Ha sido director de proyecto y del área de salud del Instituto SER de Investigación, socio y director de proyecto del Grupo de Gestión y Tecnología y chief operating officer de Agrolíbano en Honduras. Es graduado de Ingeniería de Sistemas, Especialización en Gerencia de Recursos Humanos y del Executive MBA de la Universidad de Los Andes. Es coach certificado en Action Reflection Learning por LIM Ltd. (Leadership in International Management).",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_ClaudioPena.png",
    url__linkedin: " https://www.linkedin.com/in/claudio-pe%C3%B1a-4a017a5/"
  },
  {
    id: 14,
    orderView: 11,
    name: "Matteo Chicco",
    positionEn: "Senior consultant",
    positionEs: "Consultor senior",
    descriptionEn: "Matteo is a Business Manager specialized in business communication strategies and B2B go-to market. He has 15 years of experience in business development, complex sales and collaboration with numerous Italian companies and government agencies coordinating several projects lead generation on their digital strategy and digital public relationships. Focused on innovative projects, Matteo collaborates with Italian industrial unions to develop and communicate projects of excellence. He is a contact person for the personal branding of successful professionals and entrepreneurs, and has a sound knowledge of international law, a subject in which he is developing a research thesis on the status of climate refugees. He is fluent in Italian and English.",
    descriptionEs: "Matteo es Business Manager especializado en estrategias de comunicación empresarial y B2B go to market. Tiene 15 años de experiencia en desarrollo de negocios, ventas complejas y colaboración con numerosas empresas italianas y agencias gubernamentales coordinando varios proyectos de generación de leads en su estrategia digital y relaciones públicas digitales. Enfocado en proyectos innovadores, Matteo colabora con sindicatos industriales italianos para desarrollar y comunicar proyectos de excelencia. Es una persona de contacto para el branding personal de profesionales y emprendedores exitosos y yiene un sólido conocimiento del derecho internacional, tema en el que está desarrollando una tesis de investigación sobre la situación de los refugiados climáticos. Habla italiano e inglés.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_MatteoChicco.png",
    url__linkedin: "https://www.linkedin.com/in/matteo-chicco-688065aa/"
  },
  {
    id: 15,
    orderView: 6,
    name: "Joseph Souaid",
    positionEn: "Senior consultant",
    positionEs: "Consultor senior",
    descriptionEn: "Joseph has 20+ years of experience in procurement across many industries including Food and Beverage, Airline and Aerospace, and Mining and Metals having worked across Canada, US, Mexico, and Panama.  He was recently head of Procurement at Keurig Dr Pepper Canada and previously of Copa Airlines.  Joseph holds a bachelor’s degree in Commerce and an MBA from McGill University.  He is fluent in English, French and Spanish.",
    descriptionEs: "Joseph tiene más de 20 años de experiencia en compras en muchas industrias, incluidas las de alimentos y bebidas, aerolíneas y aeroespaciales, y minería y metales habiendo trabajado en Canadá, Estados Unidos, México y Panamá. Recientemente fue director de compras en Keurig Dr Pepper Canadá y, previamente, en Copa Airlines. Joseph tiene una licenciatura en comercio y un MBA de la Universidad McGill. Habla inglés, francés y español.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_JosephSouaid.png",
    url__linkedin: "https://www.linkedin.com/in/josephsouaid/"
  },
  {
    id: 16,
    orderView: 5,
    name: "José Julián González",
    positionEn: "Senior consultant",
    positionEs: "Consultor senior",
    descriptionEn: "Jose Julian is an electronic engineer and data scientist, with an MBA from Universidad de Los Andes. He has 16 years of professional experience, last 10 working as a consultant, advising negotiation processes for companies in sectors such as oil and gas, transport, energy, mining, industrial, retail, among others. He has also trained professionals from various areas for the development of negotiation skills. He is fluent in Spanish and English.",
    descriptionEs: "José Julián es ingeniero electrónico y científico de datos, con un MBA de la Universidad de Los Andes. Tiene 16 años de experiencia profesional, los últimos 10 trabajando como consultor, asesorando procesos de negociación para empresas de sectores como oil and gas, transporte, energía, minería, industrial, retail, entre otros. También, ha capacitado a profesionales de diversas áreas para el desarrollo de habilidades de negociación. Habla inglés y español.",
    url__profile: "https://wisengine-nbd.s3.amazonaws.com/website/team/Profile_picture_JoseJulianGonzalez.png",
    url__linkedin: "https://www.linkedin.com/in/jose-julian-gonzalez-barahona/"
  },
]

export default dataOurs;
