import React from "react";
import "./CardVideo.css";
import { Link } from "react-router-dom";

function CardVideo(props) {
  const { imageHeader, title, text, id, area } = props;
  return (
    <div className={area === 1 ? "Insights__card--one " : "Insights__card"}>
      <div className="Insights__card--header">
        <Link to={`/insightsVideo/${id}`}>
          <img className="Card__header--image" src={imageHeader}  alt="Imagen CardHeader"/>
        </Link>
      </div>
      <div className="Insights__card--body">
        <Link to={`/insightsVideo/${id}`}>{title}</Link>
        <p>{text}</p>
      </div>
    </div>
  );
}
export default CardVideo;
