import React, { useState, useEffect } from "react";
import { catalogs, form } from "../../api-akeportal";
import { Link } from "react-router-dom";
import { translate } from "react-translate";
import { Helmet } from "react-helmet";

import Header from "../../components/Header";
// import Card from "../../components/Card";
import Modal from "../../components/Modal";
import UpdateInput from "../../components/UpdateInput";
import ImgHealth from "../../assets/images/banner/Recurso 2@300x-8.png";
// import ImgWebinar from "../../assets/images/Health/Recurso 3.svg";
// import Image1 from "../../assets/images/Health/Card/Recurso 1.png";
import Image2 from "../../assets/images/Health/Card/Recurso 2.png";
import Image2En from "../../assets/images/Health/Card/En/Recurso 2.png";
import Image3 from "../../assets/images/Health/Card/Recurso 3.png";
import Image3En from "../../assets/images/Health/Card/En/Recurso 3.png";
import Image4 from "../../assets/images/Health/Card/Recurso 4.png";
import Image5 from "../../assets/images/Health/Card/Recurso 5.png";
// import Image6 from "../../assets/images/Health/Card/Recurso 6.png";
// import DateStart from "../../assets/images/Health/NegotiationHealthEs.png";
import LogoNbD from "../../assets/images/Health/logo-nbd.svg";
// import ImageCheck from "../../assets/images/Health/ImageCheck.png";
import ImageDimenciones from "../../assets/images/Health/Dimenciones.png";
import ImageSietePasos from "../../assets/images/Health/SietePasos.png";
import ImageCanvas from "../../assets/images/Health/Canvas.png";
import ImagePortafolio from "../../assets/images/Health/GestionPortafolio.png";
import ImageStartIzq from "../../assets/images/Health/StartIzq.svg";
import ImageStartDer from "../../assets/images/Health/StartDer.svg";
// import ImagePresentation from "../../assets/images/Health/VIDEO RAMÓN-PABLO-13.png";

import "./Health.css";

const Health = (props) => {
  let { t } = props;
  const lang = localStorage.getItem('Lang');

  // const [lang, setLang] = useState(localStorage.getItem("Lang") || "en");
  const [isOpen, setIsOpen] = useState(false);
  const [countrys, setCountry] = useState([]);
  const [successful, setSuccessful] = useState(false);
  const [fieldEmpty, setFieldEmpty] = useState([]);
  const [register, setRegister] = useState({
    name: { value: "", error: false },
    last_name: { value: "", error: false },
    email: { value: "", error: false },
    country_id: { value: "", error: false },
    city: { value: "", error: false },
    profession: { value: "", error: false },
    company: { value: "", error: false },
    position: { value: "", error: false },
    interest: { value: "", error: false },
  });

  useEffect(() => {
    fetchData();
    // console.log("idioma",lang);
  }, []);

  const fetchData = async () => {
    let data = await catalogs();
    // console.log(data.countrys);
    setCountry(data.countrys);
  };

  const handleChange = (e, field) => {
    // Actualizar localmente en la función la neva data del perfil fisico.
    let dataRegister = {
      ...register,
      [field]: {
        ...register[field],
        value: e.target.value,
        error: false,
      },
    };

    // validación de campos vacios
    if (
      dataRegister["name"].value + "" === "" ||
      dataRegister["last_name"].value + "" === "" ||
      dataRegister["email"].value + "" === "" ||
      dataRegister["country_id"].value + "" === "" ||
      dataRegister["city"].value + "" === "" ||
      dataRegister["profession"].value + "" === "" ||
      dataRegister["company"].value + "" === "" ||
      dataRegister["position"].value + "" === "" ||
      dataRegister["interest"].value + "" === ""
    ) {
      setFieldEmpty(true);
    } else {
      setFieldEmpty(false);
    }

    setRegister(dataRegister);
  };

  const cleanRegister = () => {
    setRegister({
      name: { value: "", error: false },
      last_name: { value: "", error: false },
      email: { value: "", error: false },
      country_id: { value: "", error: false },
      city: { value: "", error: false },
      profession: { value: "", error: false },
      company: { value: "", error: false },
      position: { value: "", error: false },
      interest: { value: "", error: false },
    });
  };

  const closeModal = () => {
    setIsOpen(false);
    cleanRegister();
  };

  // Funcion para validar los campos
  const validateEmpy = async (fields, data, validator) => {
    let newData = register;

    for (var i = 0; i < fields.length; i++) {
      let field = fields[i];
      let validate = newData[field].value + "".trim();
      if (validate.length === 0) {
        newData = {
          ...newData,
          [field]: {
            ...newData[field],
            error: true,
          },
        };
      }
    }

    if (register === newData) {
      return true;
    } else {
      setRegister(newData);
      return false;
    }
  };

  const sendRegister = async () => {
    console.log("Envío de formulario: ", register);

    let validate = await validateEmpy([
      "name",
      "last_name",
      "email",
      "country_id",
      "city",
      "profession",
      "company",
      "position",
      "interest",
    ]);

    console.log("validate:", validate);

    if (validate) {
      //eslint-disable-next-line
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(register["email"].value)) {
        let newData = {
          ...register,
          'email': {
            ...register["email"],
            error: true,
          },
        };

        setRegister(newData);

        // dispatch({
        //   type:'SET_ALERTPOPUP_ONCLOSE',
        //   payload:true
        // })
        // dispatch({
        //   type:'SET_ALERTPOPUP',
        //   payload:[{
        //     color:'#fff',
        //     backgroundColor:'#ff4c3e',
        //     textAlert:'Por favor escribir un correo electrónico válido'
        //   }]
        // })
        return;
      } else {
        console.log("Cumple con los requisitos para enviar el formulario:");
        console.log("Data final:", register);

        /* Validación de comillas: */
        let insertName = register["name"].value.replace(/["']/g, "");
        let insertLastname = register["last_name"].value.replace(/["']/g, "");
        let insertCity = register["city"].value.replace(/["']/g, "");
        let insertProfession = register["profession"].value.replace(/["']/g, "");
        let insertCompany = register["company"].value.replace(/["']/g, "");
        let insertPosition = register["position"].value.replace(/["']/g, "");
        let insertInterest = register["interest"].value.replace(/["']/g, "");

        let dataForm = {
          form: "HEALTH",
          lang: lang,
          name: insertName,
          last_name: insertLastname,
          email: register["email"].value,
          country_id: register["country_id"].value,
          city: insertCity,
          profession: insertProfession,
          company: insertCompany,
          position: insertPosition,
          interest: insertInterest,
        };

        console.log("Formulario final para enviar:", dataForm);

        try {
          console.log("llamado del servicio:");
          let response = await form(dataForm);
          console.log("response form health: ", response);
          setSuccessful(true);
          closeModal();
        } catch (error) {
          console.log(error);
          console.log("error en el guardado");
          closeModal();
        }
      }
    }
  };

  const closeSuccessful = () => {
    console.log("Cerrar pop-up de confirmación:");
    setSuccessful(false);
  };

  return (
    <section className="Health__page">
      <Helmet>
        <title>Negotiation by Design | Taller salud</title>
      </Helmet>
      <div className="Banner__inscription">
        <h3>
          {t("banner_title_1")} <span> {t("banner_title_2")}</span>
        </h3>
        <div className="Button__container_banner">
          <img className="Start" src={ImageStartDer} alt="Start Left" />
          <h3 onClick={() => setIsOpen(true)}>{t("contact_us")}</h3>
          <img className="Start" src={ImageStartIzq} alt="Start Right" />
        </div>
      </div>
      <Header
        title="Title_Health_business"
        negrita="paragraph_health"
        ImageBanner={ImgHealth}
        fixedbanner={true}
      />
      <section className="Health__section_start">
        <div className="Section__start_one">
          <div className="Start__list_one">
            <p dangerouslySetInnerHTML={{ __html: t('seccion_1_text_1') }} />
          </div>
          <div className="Start__list_one">
            <p dangerouslySetInnerHTML={{ __html: t('seccion_1_text_2') }} />

          </div>
        </div>
        {/* <div className="Section__start_two">
          <div className="Seccion__two">
            <div className="start__two_text">
              <h3>¡GRACIAS POR ASISTIR A NUESTRO WEBINAR!</h3>
              <p>
                Si no tuviste la oportunidad de conectarte con nosotros, o
                quieres ver el webinar de nuevo,
                <a
                  href="https://www.youtube.com/watch?v=CwrWVh3DZrI"
                  target="_blank"
                >
                  <strong> haz click aquí.</strong>
                </a>
              </p>
            </div>
            <div className="start__two_img">
              <a
                href="https://www.youtube.com/watch?v=CwrWVh3DZrI"
                target="_blank"
              >
                <img src={ImgWebinar} alt="Video Webinar" />
              </a>
            </div>
          </div>
        </div> */}
        <div className="Seccion_start_three">
          <h3>
            {t("seccion_title_1")}
          </h3>
          <div className="Seccion__three_container">
            <div className="Start__list_one">
              <p dangerouslySetInnerHTML={{ __html: t('seccion_2_text_1') }} />

            </div>
            <div className="Start__list_one">
              <p dangerouslySetInnerHTML={{ __html: t('seccion_2_text_2') }} />
              <p>
                {t("seccion_2_text_3")} <Link to="./about">{t("seccion_2_text_4")}</Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="Health__seccion_cards">
        <div className="Seccion__cards">
          {/* <div
            className="Health__card_content"
            onClick={() =>
              window.open(
                "https://wisengine-nbd.s3.amazonaws.com/website/Presentaci%C3%B3n+webinar+-+Negociaciones+en+salud.pdf",
                "_blank"
              )
            }
          >
            <div className="Card__content_media">
              <img src={Image1} alt="imagenCard" />
            </div>
            <div className="Card__content_title">
              <p className="Card__title">{t("card_title_1")}</p>
            </div>
          </div> */}
          <div
            className="Health__card_content"
            onClick={() =>
              window.open(lang === "es" ? "https://wisengine-nbd.s3.amazonaws.com/PDF/Instrucciones+Canvas+de+Negociaci%C3%B3n.pdf" : "https://wisengine-nbd.s3.amazonaws.com/PDF/Negotiation+Canvas+instructions.pdf",
                "_blank"
              )
            }
          >
            <div className="Card__content_media">
              <img src={lang === "es" ? Image2 : Image2En} alt="imagenCard" />
            </div>
            <div className="Card__content_title">
              <p className="Card__title">
                {t("card_title_2")}
              </p>
            </div>
          </div>
          <div
            className="Health__card_content"
            onClick={() =>
              window.open(lang === "es" ? "https://wisengine-nbd.s3.amazonaws.com/ImagesPDF/Canvas+de+Negociaci%C3%B3n_Carta.pdf" : "https://wisengine-nbd.s3.amazonaws.com/ImagesPDF/Negotiation+Canvas_Letter.pdf",
                "_blank"
              )
            }
          >
            <div className="Card__content_media">
              <img src={lang === "es" ? Image3 : Image3En} alt="imagenCard" />
            </div>
            <div className="Card__content_title">
              <p className="Card__title">{t("card_title_3")}</p>
            </div>
          </div>
          <div
            className="Health__card_content"
            onClick={() =>
              window.open(lang === "es" ?
                "https://www.youtube.com/watch?v=SVH5_VW4a-U" : "https://www.youtube.com/watch?v=Fu6jeOSiOG0",
                "_blank"
              )
            }
          >
            <div className="Card__content_media">
              <img src={Image4} alt="imagenCard" />
            </div>
            <div className="Card__content_title">
              <p className="Card__title">{t("card_title_4")}</p>
            </div>
          </div>
          <div
            className="Health__card_content"
            onClick={() =>
              window.open(lang === "es" ? "https://wisengine-nbd.s3.amazonaws.com/PDF/Instrucciones+proceso+de+negociaci%C3%B3n.pdf" : "https://wisengine-nbd.s3.amazonaws.com/PDF/Negotiation+process+instructions.pdf",
                "_blank"
              )
            }
          >
            <div className="Card__content_media">
              <img src={Image5} alt="imagenCard" />
            </div>
            <div className="Card__content_title">
              <p className="Card__title">
                {t("card_title_5")}
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="Health__seccion_list">
        <div className="Seccion__list_image">
          <img src={DateStart} alt="Date Start" />
          <p className="DateStart">Iniciamos el martes 23 de febrero.</p>
          <div className="Button__container">
            <img className="Start" src={ImageStartDer} alt="Start Left" />
            <h3 onClick={() => setIsOpen(true)}>INSCRIPCIONES AQUÍ</h3>
            <img className="Start" src={ImageStartIzq} alt="Start Right" />
          </div>
        </div>

        <div className="Seccion__list_text">
          <h5>AL PARTICIPAR EN NUESTRO TALLER, TENDRÁS ACCESO A:</h5>
          <div className="List__item">
            <div className="List__item_image">
              <img src={ImageCheck} alt="Image Check" />
            </div>
            <div className="List__item_text">
              <p>Nuestra suite digital de negociación</p>
            </div>
          </div>
          <div className="List__item">
            <div className="List__item_image">
              <img src={ImageCheck} alt="Image Check" />
            </div>
            <div className="List__item_text">
              <p>Simulaciones de negociación</p>
            </div>
          </div>
          <div className="List__item">
            <div className="List__item_image">
              <img src={ImageCheck} alt="Image Check" />
            </div>
            <div className="List__item_text">
              <p>
                Entrenamiento en el uso de nuestras herramientas de negociación
              </p>
            </div>
          </div>
          <div className="List__item">
            <div className="List__item_image">
              <img src={ImageCheck} alt="Image Check" />
            </div>
            <div className="List__item_text">
              <p>
                Networking sectorial: Interacción con otros participantes y
                trabajadores del sector salud
              </p>
            </div>
          </div>
          <div className="List__item">
            <div className="List__item_image">
              <img src={ImageCheck} alt="Image Check" />
            </div>
            <div className="List__item_text">
              <p>Cuatro (4) sesiones en vivo de interacción y reflexión</p>
            </div>
          </div>
          <div className="List__item">
            <div className="List__item_image">
              <img src={ImageCheck} alt="Image Check" />
            </div>
            <div className="List__item_text">
              <p>
                2 horas de trabajo semanal en vivo y 4 horas de trabajo semanal
                autónomo
              </p>
            </div>
          </div>
          <div className="List__item">
            <div className="List__item_image">
              <img src={ImageCheck} alt="Image Check" />
            </div>
            <div className="List__item_text">
              <p>
                Uso extendido de la suite de negociación durante seis (6) meses
                a partir de la terminación del taller.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="Hearth__seccion_info">
        <div className="Seccion__info_container">
          <div className="Info__container_item">
            <h5>Fecha de inicio</h5>
            <div className="Item__container_text">
              <div className="Line__orange"></div>
              <p>Iniciamos el martes</p>
              <span>23 de febrero</span>
            </div>
          </div>
          <div className="Info__container_item">
            <h5>Duración:</h5>
            <div className="Item__container_text">
              <div className="Line__orange"></div>
              <span>Cuatro (4) semanas.</span>
              <p>
                2 horas semanales en vivo y 4 horas de trabajo autónomo semanal
              </p>
            </div>
          </div>
          <div className="Info__container_item">
            <h5>Horario</h5>
            <div className="Item__container_text">
              <div className="Line__orange"></div>
              <p>Sesiones en vivo</p>
              <span>Martes de 5:00pm a 7:00pm</span>
            </div>
          </div>
        </div>
      </section>
      <section className="Health__seccion_priceOne">
        <div className="Price__container_inversion">
          <h2>
            <strong>INVERSIÓN:</strong> 750 USD
          </h2>
          <p>
            Por la inscripción de tres personas, recibirás un cupo adicional
            totalmente gratis. (4 cupos por el valor de 3)
          </p>
        </div>
      </section>
      <section className="Health__seccion_presentation">
        <div className="Seccion__presentation_video">
          {isVideo ? (
            <div className={`Video__health ${isVideo}`}>
              <div className="Container__video">
                <iframe
                  allow="autoplay"
                  src={
                    lang === "en"
                      ? `https://www.youtube.com/embed/24wmJ3A8k8g`
                      : `https://www.youtube.com/embed/24wmJ3A8k8g`
                  }
                  frameBorder="0"
                />
              </div>
            </div>
          ) : (
            <img onClick={handleVideo} src={ImagePresentation} />
          )}
        </div>
      </section> */}
      <section className="Health__seccion_price">
        <div className="Seccion__price_container">
          <h3>
            {t("seccion_title_2")}
          </h3>
          <p>
            {t("seccion_3_text_1")}
          </p>
          <div className="Price__container_learning">
            <div className="Container__learning_item">
              <div className="Learning__item_img">
                <img src={ImageDimenciones} alt="Imagem Dimensions" />
              </div>
              <div className="Learning__item_text">
                <p>{t("seccion4_text_1")}</p>
              </div>
            </div>
            <div className="Container__learning_item">
              <div className="Learning__item_img">
                <img src={ImageSietePasos} alt="Imagen Seven Steps" />
              </div>
              <div className="Learning__item_text">
                <p>{t("seccion4_text_2")}</p>
              </div>
            </div>
            <div className="Container__learning_item">
              <div className="Learning__item_img">
                <img src={lang === "es" ? ImageCanvas : Image2En} alt="Imagen Canvas" />
              </div>
              <div className="Learning__item_text">
                <p>{t("seccion4_text_3")}</p>
              </div>
            </div>
            <div className="Container__learning_item">
              <div className="Learning__item_img">
                <img src={ImagePortafolio} alt="Imagen Canvas" />
              </div>
              <div className="Learning__item_text">
                <p>{t("seccion4_text_4")}</p>
              </div>
            </div>
          </div>

          <h3>{t("seccion_title_3")}</h3>

          <div className="Seccion__three_container">
            <div className="Start__list_one">
              <p>
                {t("seccion5_text_1")}
              </p>
            </div>
            <div className="Start__list_one">
              <p>
                {t("seccion5_text_2")}
              </p>
            </div>
          </div>

          {/* <div className="Price__container_inversion">
            <h2>
              <strong>INVERSIÓN:</strong> 750 USD
            </h2>
            <p>
              Por la inscripción de tres personas, recibirás un cupo adicional
              totalmente gratis. (4 cupos por el valor de 3)
            </p>
          </div> */}
        </div>
      </section>

      <section className="Health__seccion_inscription">
        <div className="Seccion__inscription_button">
          <div className="Button__container">
            <img src={ImageStartDer} alt="Start Left" />
            <h3 onClick={() => setIsOpen(true)}>{t("contact_us")}</h3>
            <img src={ImageStartIzq} alt="Start Right" />
          </div>
          <p>
            {t("seccion6_text_1")}
          </p>
          <span>support@negotiationbydesign.com</span>
        </div>
      </section>

      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        closeButton={true}
        background={"gray"}
      >
        <div className="Health__modal_content">
          <div className="Health__container_form">
            <div className="Health__form_header">
              <div className="Health__form_title">
                {t("form_title")}
              </div>
              <div className="Health__form_subtitle">
                {t("form_header_text")}
              </div>
              <div className="Health__form_text">
                {t("obligatory_fields")}
              </div>
            </div>
            <div className="Health__contianer_input">
              <UpdateInput
                title={t("names") + '*'}
                type="text"
                setPlacerholder={t("placeolder_name")}
                value={register["name"].value}
                alert={register["name"].error}
                onChange={(e) => {
                  handleChange(e, "name");
                }}
              />
            </div>
            <div className="Health__contianer_input">
              <UpdateInput
                title={t("lastnames") + '*'}
                type="text"
                setPlacerholder={t("placeolder_lastnames")}
                value={register["last_name"].value}
                alert={register["last_name"].error}
                onChange={(e) => {
                  handleChange(e, "last_name");
                }}
              />
            </div>
            <div className="Health__contianer_input">
              <UpdateInput
                title={t("email") + '*'}
                type="text"
                setPlacerholder={t("placeolder_email")}
                value={register["email"].value}
                alert={register["email"].error}
                onChange={(e) => {
                  handleChange(e, "email");
                }}
              />
            </div>
            <div className="Health__contianer_input">
              <UpdateInput
                title={t("country") + '*'}
                type="select"
                value={register["country_id"].value}
                alert={register["country_id"].error}
                onChange={(e) => {
                  handleChange(e, "country_id");
                }}
                options={countrys.map((country) => country.ID)}
                options_name={countrys.map((country) => country.NAME)}
              />
            </div>
            <div className="Health__contianer_input">
              <UpdateInput
                title={t("city") + '*'}
                type="text"
                setPlacerholder={t("placeolder_city")}
                value={register["city"].value}
                alert={register["city"].error}
                onChange={(e) => {
                  handleChange(e, "city");
                }}
              />
            </div>
            <div className="Health__contianer_input">
              <UpdateInput
                title={t("profession") + '*'}
                type="text"
                setPlacerholder={t("placeolder_profession")}
                value={register["profession"].value}
                alert={register["profession"].error}
                onChange={(e) => {
                  handleChange(e, "profession");
                }}
              />
            </div>
            <div className="Health__contianer_input">
              <UpdateInput
                title={t("charge") + '*'}
                type="text"
                setPlacerholder={t("placeolder_charge")}
                value={register["position"].value}
                alert={register["position"].error}
                onChange={(e) => {
                  handleChange(e, "position");
                }}
              />
            </div>
            <div className="Health__contianer_input">
              <UpdateInput
                title={t("services_info") + '*'}
                type="textarea"
                setPlacerholder={t("placeolder_services_info")}
                value={register["interest"].value}
                alert={register["interest"].error}
                onChange={(e) => {
                  handleChange(e, "interest");
                }}
              />
            </div>
          </div>
          <div className="Health__container_bottom">
            <button
              className={
                !fieldEmpty
                  ? "Health__button_form button_enabled"
                  : "Health__button_form button_disabled"
              }
              onClick={sendRegister}
            >
              {t("button_send")}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={successful}
        onClose={closeSuccessful}
        closeButton={false}
        background={"white"}
      >
        <div className="Health__button">
          <div className="Health__Button_header">
            <img className="Start" src={ImageStartDer} alt="Start Left" />
            <h3>{t("popUp_title")}</h3>
            <img className="Start" src={ImageStartIzq} alt="Start Right" />
          </div>
          <div className="Health__Button_content">
            {t("popUp_body")}
          </div>
          <div className="Health__Button_content">
            {t("popUp_footer")}
          </div>

          <div className="Button__container_image">
            <img
              className="Button__content_image"
              src={LogoNbD}
              alt="img"
            />
          </div>
          <div className="Health__container_bottom">
            <button
              className="Health__button_form button_enabled"
              onClick={closeSuccessful}
            >
              {t("popUp_button")}
            </button>
          </div>
        </div>
      </Modal>

      {/* <Modal
        isOpen={successful}
        onClose={closeSuccessful}
        closeButton={false}
        background={"white"}
      >
        <div className="Health__button">
          <div className="Health__Button_header">
            <img className="Start" src={ImageStartDer} alt="Start Left" />
            <h3>¡INSCRIPCIÓN EXITOSA!</h3>
            <img className="Start" src={ImageStartIzq} alt="Start Right" />
          </div>
          <div className="Health__Button_content">
            Recibirás un correo electrónico con la confirmación de tu
            inscripción. Si deseas contactarnos, escríbenos a{" "}
            <b>support@negotiationbydesign.com</b>
          </div>
          <div className="Button__container_image">
            <img
              className="Button__content_image"
              src="https://wisengine-nbd.s3.amazonaws.com/Recurso+11%40300x-8.png"
              alt="img"
            />
          </div>
          <div className="Health__container_bottom">
            <button
              className="Health__button_form button_enabled"
              onClick={closeSuccessful}
            >
              Continuar
            </button>
          </div>
        </div>
      </Modal> */}
    </section>
  );
};

export default translate("health")(Health);
