import React, { useState, useEffect } from "react";
import { translate } from "react-translate";
import { Element } from "react-scroll";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import "./Home.css";

import Card from "../../components/Card";

import SliderClients from "../../components/Sliders/SliderClients";
import SliderLogoCorporate from "../../components/Sliders/SliderLogo/Corporate";
import SliderLogoAcademic from "../../components/Sliders/SliderLogo/Academic";
import SliderLogoGovernment from "../../components/Sliders/SliderLogo/Government";
import SliderLogoNgo from "../../components/Sliders/SliderLogo/Ngo";
import ButtonClient from "../../components/ButtonClient";

import videoEn from "../../assets/images/Home/En/Video-canvas.png";
import videoEs from "../../assets/images/Home/Es/NbD_ES-03.png";
import imagen from "../../assets/images/Home/Home-Imagen.png";
import Image1 from "../../assets/images/Services/Services_1.png";
import Image2 from "../../assets/images/Services/Services_2.png";
import Image2_2 from "../../assets/images/Services/Services_2-es.png";

import Image3 from "../../assets/images/Services/Services_3.png";
import Image4 from "../../assets/images/Services/Services_4.png";
import Image5 from "../../assets/images/Services/Services_5.png";
import Image6 from "../../assets/images/Services/Services_6.png";

import consulting_img from "../../assets/images/Home/popup/consulting.png";
import capacity_building_img from "../../assets/images/Home/popup/capacity_building.png";
import training_img from "../../assets/images/Home/popup/Website_Services-full-Training2.png";
import platform_img from "../../assets/images/Home/popup/platform.png";
import keynotes_img from "../../assets/images/Home/popup/keynotes.png";
import coaching_img from "../../assets/images/Home/popup/coaching.png";

function Home(props) {
  let { t } = props;
  const lang = localStorage.getItem("Lang");
  const [isVideo, setisVideo] = useState("");

  useEffect(() => {
    for (var i = 1; i < document.getElementsByClassName("SliderLogo__content").length; i++) {
      document.getElementsByClassName("SliderLogo__content")[i].style.display = "none";
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const btnImg = (item) => {
    for (var i = 0; i < document.getElementsByClassName("SliderLogo__content").length; i++) {
      if (i === item) {
        document.getElementsByClassName("SliderLogo__content")[i].style.display = "";
      } else {
        document.getElementsByClassName("SliderLogo__content")[i].style.display = "none";
      }
    }
  };

  const handleVideo = (e) => {
    e.preventDefault();
    setisVideo("true");
  };

  const PupUp = {
    consulting: {
      title: "consulting_title",
      subtitle: "consulting_subtitle",
      img: consulting_img,
      text1: "consulting_text1",
      text2: "consulting_text2",
      button: "consulting_button",
    },
    platform: {
      title: "platform_title",
      subtitle: "platform_subtitle",
      img: platform_img,
      strongtext1: "platform_strongtext1",
      button: "platform_button",
    },
    capacity_building: {
      title: "capacity_building_title",
      subtitle: "capacity_building_subtitle",
      img: capacity_building_img,
      text1: "capacity_building_text1",
      text2: "capacity_building_text2",
      text3: "capacity_building_text3",
      text4: "capacity_building_text4",
      button: "capacity_building_button",
    },
    training: {
      title: "training_title",
      subtitle: "training_subtitle",
      before_img: "training_beforeImg",
      img: training_img,
      text1: "training_text1",
      text2: "training_text2",
      text3: "training_text3",
      text4: "training_text4",
      button: "training_button",
    },
    keynotes: {
      title: "keynotes_title",
      subtitle: "keynotes_subtitle",
      img: keynotes_img,
      name_list: "keynotes_list",
      list: [
        {
          id: 1,
          text: "keynotes_list1",
        },
        {
          id: 2,
          text: "keynotes_list2",
        },
        {
          id: 3,
          text: "keynotes_list3",
        },
        {
          id: 4,
          text: "keynotes_list4",
        },
        {
          id: 5,
          text: "keynotes_list5",
        },
        {
          id: 6,
          text: "keynotes_list6",
        },
        {
          id: 7,
          text: "keynotes_list7",
        },
      ],
      button: "keynotes_button",
    },
    coaching: {
      title: "coaching_title",
      subtitle: "coaching_subtitle",
      img: coaching_img,
      text1: "coaching_text1",
      text2: "coaching_text2",
      column: "1",
      button: "coaching_button",
    },
  };

  return (
    <div className="Home__page">
      <Helmet>
        <title>Negotiation by Design | Home</title>
      </Helmet>
      <Element name="home"></Element>
      <div className="Home__container">
        <div className="Home__section--principal">
          <div className="Section__principal--text">
            <div className="Principal__text">
              <h4>{t("title-1")}</h4>
              <p>{t("text-1")}</p>
            </div>
            <div className="Principal__text">
              <h4>{t("title-2")}</h4>
              <p>{t("text-2")}</p>
            </div>
            <div className="Principal__text">
              <h4>{t("title-3")}</h4>
              <p>{t("text-3")}</p>
            </div>
          </div>
          <div className="Section__principal--image">
            {isVideo ? (
              <div className={`Video__home ${isVideo}`}>
                <div className="Container__video">
                  <iframe
                    title="Video Inicial"
                    allow="autoplay"
                    src={
                      lang === "en"
                        ? `https://player.vimeo.com/video/421263144?autoplay=1`
                        : `https://player.vimeo.com/video/424932855?autoplay=1`
                    }
                    frameBorder="0"
                  />
                </div>
              </div>
            ) : (
              <img
                loading="lazy"
                onClick={handleVideo}
                src={lang === "en" ? videoEn : videoEs}
                height="auto"
                alt="Imagen video"
              />
            )}
          </div>
        </div>
        <div className="Home__section--principal">
          <div className="Principal__Image">
            <div className="Principal__Image--img">
              <img loading="lazy" src={imagen} className="img-55" height="auto" alt="Imagen for home" />
            </div>
            <div className="Section__principal--text2">
              <h4>{t("title-4")}</h4>
              <p>{t("text-4")}</p>
              <h4>{t("title-5")}</h4>
              <p>{t("text-5")}</p>
            </div>
          </div>
        </div>
        <div className="Home__section--cards">
          <h2 className="text-center">{t("title-cards")}</h2>
          <div className="Home__cards">
            <Card
              image={Image3}
              title={t("card_title_1")}
              text={t("card_text_1")}
              button={t("card_button")}
              popup={true}
              popup_title={PupUp.training.title}
              popup_subtitle={PupUp.training.subtitle}
              popup_before_img={PupUp.training.before_img}
              popup_img={PupUp.training.img}
              popup_text1={PupUp.training.text1}
              popup_text2={PupUp.training.text2}
              popup_text3={PupUp.training.text3}
              popup_text4={PupUp.training.text4}
              popup_button={PupUp.training.button}
            />
            <Card
              image={lang === "en" ? Image2 : Image2_2}
              title={t("card_title_2")}
              text={t("card_text_2")}
              button={t("card_button")}
              popup={true}
              popup_title={PupUp.consulting.title}
              popup_subtitle={PupUp.consulting.subtitle}
              popup_img={PupUp.consulting.img}
              popup_text1={PupUp.consulting.text1}
              popup_text2={PupUp.consulting.text2}
              popup_button={PupUp.consulting.button}
            />
          </div>
          <div className="Home__cards">
            <Card
              image={Image6}
              title={t("card_title_3")}
              text={t("card_text_3")}
              button={t("card_button")}
              popup={true}
              popup_title={PupUp.platform.title}
              popup_subtitle={PupUp.platform.subtitle}
              popup_img={PupUp.platform.img}
              popup_strongtext1={PupUp.platform.strongtext1}
              popup_button={PupUp.platform.button}
            />
            <Card
              image={Image1}
              title={t("card_title_4")}
              text={t("card_text_4")}
              button={t("card_button")}
              popup={true}
              popup_title={PupUp.capacity_building.title}
              popup_subtitle={PupUp.capacity_building.subtitle}
              popup_img={PupUp.capacity_building.img}
              popup_text1={PupUp.capacity_building.text1}
              popup_text2={PupUp.capacity_building.text2}
              popup_text3={PupUp.capacity_building.text3}
              popup_text4={PupUp.capacity_building.text4}
              popup_button={PupUp.capacity_building.button}
            />
          </div>
          <div className="Home__cards">
            <Card
              image={Image5}
              title={t("card_title_5")}
              text={t("card_text_5")}
              button={t("card_button")}
              popup={true}
              popup_title={PupUp.keynotes.title}
              popup_subtitle={PupUp.keynotes.subtitle}
              popup_img={PupUp.keynotes.img}
              name_list={PupUp.keynotes.name_list}
              list={PupUp.keynotes.list}
              popup_button={PupUp.keynotes.button}
            />
            <Card
              image={Image4}
              title={t("card_title_6")}
              text={t("card_text_6")}
              button={t("card_button")}
              popup={true}
              popup_title={PupUp.coaching.title}
              popup_subtitle={PupUp.coaching.subtitle}
              popup_img={PupUp.coaching.img}
              popup_text1={PupUp.coaching.text1}
              popup_text2={PupUp.coaching.text2}
              popup_text3={PupUp.coaching.text3}
              popup_text4={PupUp.coaching.text4}
              popup_button={PupUp.coaching.button}
            />
          </div>
        </div>
      </div>
      <div className="Home__section--coments">
        <div className="Section__coments_container">
          <h2>{t("title_2")}</h2>
          <div className="Section__cards">
            <SliderClients />
          </div>
        </div>
      </div>
      <div>
        {/* slider our client */}
        <div className="Home__client">
          <div className="Home__client--title">
            <h2>{t("client_title")}</h2>
          </div>
          <div className="Home__client--button">
            <ButtonClient
              text="corporate"
              btnImg={(e) => {
                btnImg(0);
              }}
            />
            <ButtonClient
              text="academic"
              btnImg={(e) => {
                btnImg(1);
              }}
            />
            <ButtonClient
              text="government"
              btnImg={(e) => {
                btnImg(2);
              }}
            />
            <ButtonClient
              text="ngo"
              btnImg={(e) => {
                btnImg(3);
              }}
            />
          </div>
          <div className="Home__client--slider">
            <div className="SliderLogo__content">
              <SliderLogoCorporate />
            </div>
            <div className="SliderLogo__content">
              <SliderLogoAcademic />
            </div>
            <div className="SliderLogo__content">
              <SliderLogoGovernment />
            </div>
            <div className="SliderLogo__content">
              <SliderLogoNgo />
            </div>
          </div>
        </div>
      </div>
      <div className="Home__section">
        <h3 className="section--title">{t('title_3')}</h3>
        <Link className="Home__button " to="/contact">
          {t('title_button')}
        </Link>
      </div>
    </div>
  );
};

export default translate("home")(Home);
