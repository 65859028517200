import React, {useState} from 'react';
import { translate } from "react-translate";

// import * as moment from 'moment';
// import { ReactDatez } from 'react-datez';

// import SvgIcon from '../../assets/images/SvgIcon.js';
// Importante validar la creación de un componente de tipo svg.
// Validar así mismo la implementación de un input con icono.

import './UpdateInput.css';

function UpdateInput({
  t,
  title,
  type,
  name,
  setPlacerholder,
  onChange,
  value,
  options,
  options_name,
  disabled,
  // endDate,
  // startDate,
  suggestion,
  textSuggestion,
  setIcon,
  // setNameIcon,
  setStateIcon,
  setElement,
  updateShow,
  alert,
  onKeyPress,
  defaultOption = true,
}) {
  const [flag, setflag] = useState(false);
  const colorProgress = (e) => {
    var element = e.target;
    var val =
      (element.value - element.attributes.min.nodeValue) /
      (element.attributes.max.nodeValue - element.attributes.min.nodeValue);
    var percent = val * 100;

    element.style.background =
      '-webkit-gradient(linear, left top, right top, ' +
      'color-stop(' +
      percent +
      '%, #FFD067), ' +
      'color-stop(' +
      percent +
      '%, #efefef)' +
      ')';

    element.style.background =
      '-moz-linear-gradient(left center, #FFD067 0%, #FFD067 ' +
      percent +
      '%, #efefef ' +
      percent +
      '%, #efefef 100%)';
  };

  /*
  * Input tipo texto, numero o tiempo
  */
  if (type === 'text' || type === 'number' || type === 'time') {
    return (
      <React.Fragment>
        <div className="UpdateInput__container">
          {title && (
            <div className="UpdateInput__container_label">
              <p className="UpdateInput__label">{title}</p>
            </div>
          )}
          <div
            className={
              alert
                ? `UpdateInput__container_input button__alert`
                : `UpdateInput__container_input`
            }
          >
            <input
              className="UpdateInput__input"
              placeholder={setPlacerholder}
              type={type}
              value={value}
              onChange={onChange}
            />
            {setIcon && (
              <div
                className="Forms__container_icon"
                onClick={() => updateShow(setElement)}
              >
                {/* <SvgIcon
                  className="Forms__input_icon"
                  name={
                    setStateIcon ? `${setNameIcon}Show` : `${setNameIcon}Hidden`
                  }
                  setFillColor={`#3F3F3F`}
                /> */}
              </div>
            )}
          </div>
          {suggestion && (
            <div>
              <p>{textSuggestion}</p>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  /*
  * Input tipo email
  * Validación mediante expresión regular para validar estructura de correo electronico:
  */

  const onChangeEmail = (e) => {
    if(flag){
      onChange( e.target.value)
    return
    }
  };

  const handleLoginKeyUp = (e) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault()
    }else{
    setflag(true)
    }
  };

  if (type === 'email') {
    return (
      <React.Fragment>
        <div className="UpdateInput__container">
          {title && (
            <div className="UpdateInput__container_label">
              <p className="UpdateInput__label">{title}</p>
            </div>
          )}
          <div
            className={
              alert
                ? `UpdateInput__container_input button__alert`
                : `UpdateInput__container_input`
            }
          >
            <input
              className="UpdateInput__input"
              placeholder={setPlacerholder}
              type={type}
              value={value}
              onKeyPress={(e)=>handleLoginKeyUp(e)}
              onChange={(e)=> onChangeEmail(e)}
            />

          </div>
          {suggestion && (
            <div>
              <p>{textSuggestion}</p>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  // if (type === 'date') {
  //   return (
  //     <React.Fragment>
  //       <div className="UpdateInput__container">
  //         {title && (
  //           <div className="UpdateInput__container_label">
  //             <p className="UpdateInput__label">{title}</p>
  //           </div>
  //         )}
  //         <ReactDatez
  //           name="birthday"
  //           allowPast={true}
  //           dateFormat="MMM DD, YYYY"
  //           handleChange={(date) =>
  //             onChange(moment(date).locale('en').format('MMM DD, YYYY'))
  //           }
  //           value={value}
  //           startDate={startDate}
  //           endDate={endDate}
  //           showTime={{ use12Hours: true }}
  //         />
  //       </div>
  //     </React.Fragment>
  //   );
  // };

  // if (type === 'datetime') {
  //   return (
  //     <React.Fragment>
  //       <div className="UpdateInput__container">
  //         {title && (
  //           <div className="UpdateInput__container_label">
  //             <p className="UpdateInput__label">{title}</p>
  //           </div>
  //         )}
  //         <div
  //           className={
  //             alert
  //               ? `UpdateInput__container_input button__alert`
  //               : `UpdateInput__container_input `
  //           }
  //         >
  //         </div>
  //       </div>
  //     </React.Fragment>
  //   );
  // }

  /*
  * Input de tipo contraseña:
  * Presenta el contenido con seguridad y permmite la visualización icono para mostrar contraseña.
  */
  if (type === 'password') {
    return (
      <React.Fragment>
        <div className="UpdateInput__container">
          {title && (
            <div className="UpdateInput__container_label">
              <p className="UpdateInput__label">{title}</p>
            </div>
          )}
          <div
            className={
              alert
                ? `UpdateInput__container_input button__alert`
                : `UpdateInput__container_input`
            }
          >
            <input
              onKeyPress={onKeyPress}
              className="UpdateInput__input"
              placeholder={setPlacerholder}
              type={setStateIcon ? `text` : type}
              value={value}
              onChange={onChange}
            />
            {setIcon && (
              <div
                className="Forms__container_icon"
                onClick={() => updateShow(setElement)}
              >
                {/* <SvgIcon
                  className="Forms__input_icon"
                  name={
                    setStateIcon ? `${setNameIcon}Show` : `${setNameIcon}Hidden`
                  }
                  setFillColor={`#3F3F3F`}
                /> */}
              </div>
            )}
          </div>
          {suggestion && (
            <div>
              <p>{textSuggestion}</p>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  if (type === 'select') {
    return (
      <React.Fragment>
        <div className="UpdateInput__container">
          {title && (
            <div className="UpdateInput__container_label">
              <p className="UpdateInput__label">{title}</p>
            </div>
          )}
          <div
            className={
              alert
                ? `UpdateInput__container_input button__alert`
                : `UpdateInput__container_input`
            }
          >

            <select
              className="UpdateInput__input"
              name="Genero"
              value={value}
              onChange={onChange}
            >
              {defaultOption &&
                <option value="">{setPlacerholder ? setPlacerholder : t("option_select")}</option>
              }
              {options.map((opt, index) => {
                return (
                  <option key={index} value={`${opt}`}>
                    {options_name === undefined ? opt : options_name[index]}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (type === 'radio') {
    return (
      <React.Fragment>
        <div className="UpdateInput__container">
          {title && (
            <div className="UpdateInput__container_label">
              <p className="UpdateInput__label">{title}</p>
            </div>
          )}
          <div className="UpdateInput__container_input_radio">
            {options.map((opt, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="UpdateInput__label_radio">
                    <input
                      className="UpdateInput__input_radio"
                      type={type}
                      name={name}
                      value={`${opt}`}
                      onClick={onChange}
                      defaultChecked={opt === value ? 'checked' : ''}
                    />
                    <label
                      htmlFor={
                        options_name === undefined ? opt : options_name[index]
                      }
                    >
                      {options_name === undefined ? opt : options_name[index]}
                    </label>
                    <span className="radiomark"></span>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (type === 'range') {
    return (
      <React.Fragment>
        <div className="UpdateInput__container">
          {title && (
            <div className="UpdateInput__container_label">
              <p className="UpdateInput__label">{title}</p>
            </div>
          )}
          <input
            className="UpdateInput__input_range"
            type={type}
            min="1"
            max="10"
            value={value}
            onChange={onChange}
            disabled={disabled}
            onMouseMove={(e) => colorProgress(e)}
          />
          <div className="UpdateInput__input_range_span">
            <p>1</p>
            <p>5</p>
            <p>10</p>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (type === 'checkbox') {
    return (
      <React.Fragment>
        {value === 'N' && (
          <div className="UpdateInput__container_checkbox">
            <label className="UpdateInput__label_checkbox" htmlFor={name}>
              {name}
              <input
                className="UpdateInput__input_checkbox"
                type={type}
                name={name}
                onChange={onChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        )}
        {value === 'Y' && (
          <div className="UpdateInput__container_checkbox">
            <label className="UpdateInput__label_checkbox" htmlFor={name}>
              {name}
              <input
                className="UpdateInput__input_checkbox"
                type={type}
                name={name}
                onChange={onChange}
                checked
              />
              <span className="checkmark"></span>
            </label>
          </div>
        )}
      </React.Fragment>
    );
  }

  if (type === 'checkbox number') {
    return (
      <React.Fragment>
        {value === 0 && (
          <div className="UpdateInput__container_checkbox">
            <label className="UpdateInput__label_checkbox" htmlFor={name}>
              {name}
              <input
                className="UpdateInput__input_checkbox"
                type="checkbox"
                name={name}
                onChange={onChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        )}
        {value === 1 && (
          <div className="UpdateInput__container_checkbox">
            <label className="UpdateInput__label_checkbox" htmlFor={name}>
              {name}
              <input
                className="UpdateInput__input_checkbox"
                type="checkbox"
                name={name}
                onChange={onChange}
                checked
              />
              <span className="checkmark"></span>
            </label>
          </div>
        )}
      </React.Fragment>
    );
  }

  if (type === 'textarea') {
    return (
      <React.Fragment>
        <div className="UpdateInput__container">
          {title && (
            <div className="UpdateInput__container_label">
              <p className="UpdateInput__label">{title}</p>
            </div>
          )}
          <div
            className={
              alert
                ? `UpdateInput__container_input button__alert`
                : `UpdateInput__container_input`
            }
          >

            <textarea
              className="UpdateInput__input"
              rows="2"
              cols="5"
              maxLength="961"
              placeholder={setPlacerholder}
              value={value}
              onChange={onChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (type === 'file') {
    return (
      <React.Fragment>
        <div className="UpdateInput__container">
          {title && (
            <div className="UpdateInput__container_label">
              <p className="UpdateInput__label">{title}</p>
            </div>
          )}
          <div className="UpdateInput__container_input">
            <input
              type={type}
              className="UpdateInput__input"
              value={value}
              onChange={onChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  return null;
};

export default translate("updateInput")(UpdateInput);
