import Arboard1 from '../../assets/images/Insights/En/Artboard1.png';
import Arboard2 from '../../assets/images/Insights/En/Artboard2.jpg';
import Arboard3 from '../../assets/images/Insights/En/Artboard3.jpg';
import Arboard4 from '../../assets/images/Insights/En/Artboard4.jpg';
import Arboard5 from '../../assets/images/Insights/En/Artboard5.jpg';
import Arboard6 from '../../assets/images/Insights/En/Artboard6.jpg';
import Arboard7 from '../../assets/images/Insights/En/Artboard7.jpg';
import Arboard8 from '../../assets/images/Insights/En/Artboard8.jpg';
import Arboard9 from '../../assets/images/Insights/En/Artboard9.jpg';
import Arboard10 from '../../assets/images/Insights/En/Artboard10.jpg';
import Arboard11 from '../../assets/images/Insights/En/Artboard11.jpg';
import Arboard12 from '../../assets/images/Insights/En/Artboard12.jpg';
import Arboard13 from '../../assets/images/Insights/En/Artboard13.jpg';
import Arboard14 from '../../assets/images/Insights/En/Artboard14.jpg';
import Arboard15 from '../../assets/images/Insights/En/Artboard15.jpg';
import Arboard16 from '../../assets/images/Insights/En/Artboard16.jpg';
import Arboard17 from '../../assets/images/Insights/En/Artboard17.jpg';
import Arboard18 from '../../assets/images/Insights/En/Artboard18.jpg';
import Arboard19 from '../../assets/images/Insights/En/Artboard19.jpg';
import Arboard20 from '../../assets/images/Insights/En/Artboard20.jpg';
import Arboard21 from '../../assets/images/Insights/En/Artboard21.jpg';
import Arboard22 from "../../assets/images/Insights/En/OrangeExample3.0.png";
import VideoPablo from '../../assets/images/Insights/En/video-blog-01.png';

import Arboard1Es from '../../assets/images/Insights/Es/Artboard1.jpg';
import Arboard2Es from '../../assets/images/Insights/Es/Artboard2.jpg';
import Arboard3Es from '../../assets/images/Insights/Es/Artboard3.jpg';
import Arboard4Es from '../../assets/images/Insights/Es/Artboard4.jpg';
import Arboard5Es from '../../assets/images/Insights/Es/Artboard5.jpg';
import Arboard6Es from '../../assets/images/Insights/Es/Artboard6.jpg';
import Arboard7Es from '../../assets/images/Insights/Es/Artboard7.jpg';
import Arboard8Es from '../../assets/images/Insights/Es/Artboard8.jpg';
import Arboard9Es from '../../assets/images/Insights/Es/Artboard9.jpg';
import Arboard10Es from '../../assets/images/Insights/Es/Artboard10.jpg';
import Arboard11Es from '../../assets/images/Insights/Es/Artboard11.jpg';
import Arboard12Es from '../../assets/images/Insights/Es/Artboard12.jpg';
import Arboard13Es from '../../assets/images/Insights/Es/Artboard13.jpg';
import Arboard14Es from '../../assets/images/Insights/Es/Artboard14.jpg';
import Arboard15Es from '../../assets/images/Insights/Es/Artboard15.jpg';
import Arboard16Es from '../../assets/images/Insights/Es/Artboard16.jpg';
import Arboard17Es from '../../assets/images/Insights/Es/Artboard17.jpg';
import Arboard18Es from '../../assets/images/Insights/Es/Artboard18.jpg';
import Arboard19Es from '../../assets/images/Insights/Es/Artboard19.jpg';
import Arboard20Es from '../../assets/images/Insights/Es/Artboard20.jpg';
import Arboard21Es from '../../assets/images/Insights/Es/Artboard21.jpg';
import Arboard22Es from "../../assets/images/Insights/Es/NegociacionesSalud.jpg";
// import VideoPabloEs from '../../assets/images/Insights/Es/video-blog-01.png';
const dataBlog = [
  {
    id: 1,
    orderView: 1,
    image_en: Arboard1,
    image_es: Arboard1Es,
    title_en: 'Negotiation by Design: Intro to the Negotiation Canvas',
    text_en: '70 % of the success of a negotiation is in the preparation.The Canvas is the most efficient and effective way to help you prepare.',
    fecha_en: "6 June, 2019",
    video_en: 'https://player.vimeo.com/video/321299316',
    title_es: 'Introducción al Canvas de Negociación',
    text_es: 'El 70% del éxito de una negociación está en la preparación. Empiece a usar el Canvas de Negociación para mejorar sus negociaciones.',
    fecha_es: "6 Junio, 2019",
    video_es: 'https://player.vimeo.com/video/339426714',
  },
  {
    id: 2,
    orderView: 23,
    image_en: Arboard2,
    image_es: Arboard2Es,
    title_en: 'The Orange Example 2.0 Negotiation by Design',
    text_en: 'Watch the Orange Example 2.0 video to help you get a head start on understanding how to use the Negotiation Canvas. ',
    fecha_en: "14 June, 2019",
    video_en: 'https://player.vimeo.com/video/316620881',
    title_es: 'Canvas de Negociación: Ejemplo de la Naranja',
    text_es: 'Aprenda a usar el Canvas de Negociación por medio de este ejemplo simple pero poderoso.',
    fecha_es: "14 Junio, 2019",
    video_es: 'https://player.vimeo.com/video/326616959',
  },
  {
    id: 3,
    orderView: 4,
    image_en: Arboard3,
    image_es: Arboard3Es,
    title_en: 'Mapping the actors in Negotiation: Part 2',
    text_en: 'In important negotiations, you need to know who is with you and who is against you. Then, you need a plan to bring them over to your side.',
    fecha_en: "13 June, 2019",
    video_en: 'https://www.youtube.com/embed/nBGjnNwlBfI',
    title_es: 'Los cinco niveles de acuerdo y confianza',
    text_es: '"Determine qué tipo de relación tiene con sus actores: ¿Son Aliados, adversarios, oponentes, compañeros obligados o indecisos?',
    fecha_es: "13 Junio, 2019",
    video_es: 'https://www.youtube.com/embed/6RFDCgcGgb0',
  },
  {
    id: 4,
    orderView: 5,
    image_en: Arboard4,
    image_es: Arboard4Es,
    title_en: 'Mapping the actors in Negotiation: Part 1',
    text_en: 'Do you know who you are negotiating with? There are three types of actors in a negotiation: decision makers, influencers and implementors. ',
    fecha_en: "10 September, 2018",
    video_en: 'https://www.youtube.com/embed/xsFqveWHXuQ',
    title_es: 'Los 3 tipos de actores en una negociación',
    text_es: 'Existen 3 tipos de actores en una negociación: los que toman las decisiones, los influenciadores y los implementadores.',
    fecha_es: "10 Septiembre, 2018",
    video_es: 'https://www.youtube.com/embed/cs8YM55bc2A',
  },
  {
    id: 5,
    orderView: 6,
    image_en: Arboard5,
    image_es: Arboard5Es,
    title_en: 'Mapping the actors in Negotiation: Part 1',
    text_en: 'Do you know who you are negotiating with? There are three types of actors in a negotiation: decision makers, influencers and implementors. ',
    fecha_en: "10 September, 2018",
    video_en: 'https://www.youtube.com/embed/w51R8mnOwYo',
    title_es: 'Los 3 tipos de actores en una negociación',
    text_es: 'Existen 3 tipos de actores en una negociación: los que toman las decisiones, los influenciadores y los implementadores.',
    fecha_es: "10 Septiembre, 2018",
    video_es: 'https://www.youtube.com/embed/u_8L1ocJ2-A',
  },
  {
    id: 6,
    orderView: 7,
    image_en: Arboard6,
    image_es: Arboard6Es,
    title_en: 'MESOS: The Most Powerful Tactic in Negotiation',
    text_en: 'MESOS can help you anchor a negotiation to your favor and get your counterpart on board.',
    fecha_en: "19 July, 2018",
    video_en: 'videohttps://www.youtube.com/embed/vyDIJfzfXnQ_6',
    title_es: 'MOSES, La táctica más poderosa en Negociación',
    text_es: 'Las ofertas múltiples son una excelente manera de asegurarse de obtener más de lo que desea en una negociación.',
    fecha_es: "19 Julio, 2018",
    video_es: 'https://www.youtube.com/embed/KqpaSPHolIk',
  },
  {
    id: 7,
    orderView: 8,
    image_en: Arboard7,
    image_es: Arboard7Es,
    title_en: 'Negotiating with Bullies: Ultimatums',
    text_en: 'No one likes an ultimatum. How do you diffuse and get beyond ultimatums in a negotiation? Watch this video and find out.',
    fecha_en: " 9 July, 2018",
    video_en: 'https://www.youtube.com/embed/uhFKQ7kxrHA',
    title_es: 'Negociando con Bullies: ULTIMÁTUMS',
    text_es: 'Los ultimátums pueden parecer el final de una negociación, pero no tiene que ser así.',
    fecha_es: "9 Julio, 2018",
    video_es: 'https://www.youtube.com/embed/jSKfAADd9dc',
  },
  {
    id: 8,
    orderView: 9,
    image_en: Arboard8,
    image_es: Arboard8Es,
    title_en: 'Everyone has found themselves negotiating with a bully at least once in their career.',
    text_en: 'No one likes an ultimatum. How do you diffuse and get beyond ultimatums in a negotiation? Watch this video and find out.',
    fecha_en: " 9 July, 2018",
    video_en: 'https://www.youtube.com/embed/Ubr0kpOq-wA',
    title_es: 'Negociando con Bullies: ULTIMÁTUMS',
    text_es: 'Los ultimátums pueden parecer el final de una negociación, pero no tiene que ser así.',
    fecha_es: "9 Julio, 2018",
    video_es: 'https://www.youtube.com/embed/lOymw1n88Po',
  },
  {
    id: 9,
    orderView: 10,
    image_en: Arboard9,
    image_es: Arboard9Es,
    title_en: 'Negotiating with your children: Calming the storm',
    text_en: 'Children are angels until they are not. Watch this video to learn some tips for calming the storm when you negotiate with your kids.',
    fecha_en: "6 June, 2018",
    video_en: 'https://www.youtube.com/embed/xJ3Xu_9Jlb4',
    title_es: '¿Cómo negociar con los hijos? Calmando la tormenta',
    text_es: 'Los niños parecen ángeles, pero no siempre. En este video te damos algunos tips para calmar la tormenta cuando negocias con tus hijos.',
    fecha_es: "6 Junio, 2018",
    video_es: 'https://www.youtube.com/embed/VHh-T8c0hQg',
  },
  {
    id: 10,
    orderView: 11,
    image_en: Arboard10,
    image_es: Arboard10Es,
    title_en: 'Negotiating with your children: Three tactics',
    text_en: 'Got kids, we can help. Negotiation is a present part of our daily life. This can be one of the most difficult subjects for a parent.',
    fecha_en: "28 May, 2018",
    video_en: 'https://www.youtube.com/embed/1kCoUWzLhkM',
    title_es: 'Tres tácticas para negociar con tu hijos',
    text_es: 'Negociar con los niños puede ser uno de los temas más difíciles para un padre. Aplicar estas tácticas puede ayudarle.',
    fecha_es: "28 Mayo, 2018",
    video_es: 'https://www.youtube.com/embed/xxI1Wpf-T6w',
  },
  {
    id: 11,
    orderView: 12,
    image_en: Arboard11,
    image_es: Arboard11Es,
    title_en: 'Factors that motivate people to say yes: Power balance.',
    text_en: 'Shifting the balance of power requires strategy and integrity. Here is an example that illustrates the concept.',
    fecha_en: "4 May, 2018",
    video_en: 'https://www.youtube.com/embed/1-CFdsnLZpI',
    title_es: 'Factores que llevan a las personas a decir “Sí”: PODER.',
    text_es: 'Lleve su contraparte a la mesa cambiando el balance de poder. Cambie el poder a su favor de manera legítima.',
    fecha_es: "4 Mayo, 2018",
    video_es: 'https://www.youtube.com/embed/Iy3DZqDmiuU',
  },
  {
    id: 12,
    orderView: 13,
    image_en: Arboard12,
    image_es: Arboard12Es,
    title_en: 'Factors that motivate people to say yes: Belief systems.',
    text_en: 'Want to get someone to say ‘yes’ in a negotiation? Make sure it perceived as fair.',
    fecha_en: "2 May, 2018",
    video_en: 'https://www.youtube.com/embed/dxi3oKtFD0U',
    title_es: 'Factores que llevan a la gente a decir “Sí”: CREENCIAS',
    text_es: '¿Quieres conseguir un “Sí” en negociación? Asegúrate de que tus negociaciones sean percibidas como justas.',
    fecha_es: "2 Mayo, 2018",
    video_es: 'https://www.youtube.com/embed/Jcn0lGjhQF0',
  },
  {
    id: 13,
    orderView: 14,
    image_en: Arboard13,
    image_es: Arboard13Es,
    title_en: 'Factors that motivate people to say yes: Interests',
    text_en: 'Interests are what drives a negotiation. Watch this video to make sure you know what they are and how to move from positions to interests.',
    fecha_en: "2 May, 2018",
    video_en: 'https://www.youtube.com/embed/KDjOy-nX1O8',
    title_es: 'Factores que llevan a la gente a decir “Sí”: INTERESES',
    text_es: 'Los intereses son la base de toda negociación. Aprenda como pasar de una posición a un interés.',
    fecha_es: "2 Mayo, 2018",
    video_es: 'https://www.youtube.com/embed/LJCSz99KtwY',
  },
  {
    id: 14,
    orderView: 15,
    image_en: Arboard14,
    image_es: Arboard14Es,
    title_en: 'Factors that motivate people to say yes in negotiation.',
    text_en: 'There are four factors that affect decision making in negotiation. If you play each one correctly, you are bound to reach a deal.',
    fecha_en: "2 May, 2018",
    video_en: 'https://www.youtube.com/embed/rAxE_t7jAK0',
    title_es: '¿Qué lleva a la gente a decir “Sí” en una negociación?',
    text_es: 'Hay cuatro factores que afectan la toma de decisiones en una negociación, manéjelos y será más probable que logre un acuerdo.',
    fecha_es: "2 Mayo, 2018",
    video_es: 'https://www.youtube.com/embed/qoMSv9jQ6kM',
  },
  {
    id: 15,
    orderView: 16,
    image_en: Arboard15,
    image_es: Arboard15Es,
    title_en: 'What do you share and what do you keep secret?',
    text_en: 'What information do you share and what do you usually keep secret? We’ve all been there before and privacy does not mean power.',
    fecha_en: "2 May, 2018",
    video_en: 'https://www.youtube.com/embed/qn2gFOnvwoI',
    title_es: '¿Qué compartes y qué mantienes en secreto?',
    text_es: '¿Qué información comparte y qué suele mantener en secreto? Todos hemos estado allí antes y la privacidad no significa poder.',
    fecha_es: "2 Mayo, 2018",
    video_es: 'https://www.youtube.com/embed/qn2gFOnvwoI',
  },
  {
    id: 16,
    orderView: 17,
    image_en: Arboard16,
    image_es: Arboard16Es,
    title_en: 'Who should make the first offer during a negotiation?',
    text_en: 'Professor Pablo Restrepo answers who should make the first offer when negotiating and why.',
    fecha_en: " 2 May, 2018",
    video_en: 'https://www.youtube.com/embed/kC0PIfY4UV4',
    title_es: '¿Quién debe hacer la primera oferta durante una negociación?',
    text_es: 'El profesor Pablo Restrepo responde quién debe hacer la primera oferta al negociar y por qué.',
    fecha_es: "2 Mayo, 2018",
    video_es: 'https://www.youtube.com/embed/kC0PIfY4UV4',
  },
  {
    id: 17,
    orderView: 18,
    image_en: Arboard17,
    image_es: Arboard17Es,
    title_en: 'What is the most powerful negotiation tactic?',
    text_en: 'With 25+ years of experience, Professor Pablo Restrepo shares his #1 most powerful tactic in negotiation: Multiple Proposals.',
    fecha_en: "2 May, 2018",
    video_en: 'https://www.youtube.com/embed/iDVBOKqT10Y',
    title_es: '¿Cuál es la táctica de negociación más poderosa?',
    text_es: 'Con más de 25 años de experiencia, el profesor Pablo Restrepo comparte su táctica más poderosa en la negociación: Múltiples propuestas.',
    fecha_es: "2 Mayo, 2018",
    video_es: 'https://www.youtube.com/embed/akuKhRS1CHM',
  },
  {
    id: 18,
    orderView: 19,
    image_en: Arboard18,
    image_es: Arboard18Es,
    title_en: 'What is the impact of culture in negotiation?',
    text_en: 'Cultural differences can make negotiations difficult. This short video gives you two key ways to build a bridge to your counterpart.',
    fecha_en: "2 May, 2018",
    video_en: 'https://www.youtube.com/embed/P6KBLsFjmsU',
    title_es: '¿Cuál es el impacto de la cultura en la negociación?',
    text_es: 'Las diferencias culturales pueden dificultar las negociaciones. Este corto video le da dos maneras clave de construir un puente con su contraparte.',
    fecha_es: "2 Mayo, 2018",
    video_es: 'https://www.youtube.com/embed/dR1PGBXc2Gc',
  },
  {
    id: 19,
    orderView: 20,
    image_en: Arboard19,
    image_es: Arboard19Es,
    title_en: 'How do you deal with anger in negotiations?',
    text_en: 'We’ve all been there before, we’ve all gotten angry during a negotiation.',
    fecha_en: "13 March, 2018",
    video_en: 'https://www.youtube.com/embed/iV9cRVtR25Y',
    title_es: '¿Cómo manejar la rabia durante una negociación?',
    text_es: 'La ira puede ser su peor enemigo al negociar. Aprenda cómo hacer para controlarla.',
    fecha_es: "13 Marzo, 2018",
    video_es: 'https://www.youtube.com/embed/EWMSlckFAys',
  },
  {
    id: 20,
    orderView: 21,
    image_en: Arboard20,
    image_es: Arboard20Es,
    title_en: 'How do you shift the balance of power?',
    text_en: 'Aluna founder, Pablo Restrepo, answers the top five questions he gets asked about negotiation.',
    fecha_en: "2 March, 2018",
    video_en: 'https://www.youtube.com/embed/M31t-7wm0zA',
    title_es: '¿Cómo cambiar el balance de poder en una negociación?',
    text_es: 'Una de las preguntas más frecuentes en negociación: ¿Cómo cambiar el balance de poder a su favor?',
    fecha_es: "2 Marzo, 2018",
    video_es: 'https://www.youtube.com/embed/cAli2Kxbdbw',
  },
  {
    id: 21,
    orderView: 22,
    image_en: Arboard21,
    image_es: Arboard21Es,
    title_en: 'What is the most important quality of a good negotiator?',
    text_en: 'In this first of five videos, Professor Restrepo answers the question: What is the most important quality of a good negotiator?',
    fecha_en: "2 March, 2018",
    video_en: 'https://www.youtube.com/embed/l-AfFmfbYvQ',
    title_es: '¿Cuál es la cualidad más importante de un buen negociador?',
    text_es: 'En este primer video, el profesor Pablo Restrepo nos cuenta cuál es la cualidad más importante de un negociador.',
    fecha_es: "2 Marzo, 2018",
    video_es: 'https://www.youtube.com/embed/rikTExLuUP8',
  },
  {
    id: 22,
    orderView: 23,
    image_en: VideoPablo,
    image_es: VideoPablo,
    title_en: 'Welcome to the Negotiation by Design Video Blog',
    text_en: 'With over two decades of experience in training and consulting around the world, Pablo Restrepo has a lot to say about negotiation.',
    fecha_en: "2 March, 2018",
    video_en: 'https://www.youtube.com/embed/pzOW9IotiVo',
    title_es: 'Bienvenido al Video Blog de Negociación por Diseño',
    text_es: 'Con más de dos décadas de experiencia en entrenamiento y consultoría alrededor del mundo, Pablo Restrepo tiene mucho que decir sobre la negociación.',
    fecha_es: "2 Marzo, 2018",
    video_es: 'https://www.youtube.com/embed/pzOW9IotiVo',
  },
  {
    id: 24,
    orderView: 2,
    image_en: Arboard22,
    image_es: Arboard22,
    title_en: "The Orange Example 3.0",
    text_en: "This video will illustrate the power of the Negotiation Canvas 3.0 through a classic example of two siblings fighting over an orange.",
    fecha_en: "26 January 2021",
    video_en: 'https://www.youtube.com/embed/Fu6jeOSiOG0',
    title_es: "Ejemplo de la naranja 3.0",
    text_es: "   Este video ilustra el poder del Canvas de Negociación 3.0 a través de un ejemplo clásico de dos hermanas peleando por una naranja.",
    fecha_es: "26 Enero, 2021",
    video_es: 'https://www.youtube.com/watch?v=SVH5_VW4a-U',
  },
  {
    id: 23,
    orderView: 3,
    image_en: "",
    image_es: Arboard22Es,
    title_en: "",
    text_en: "",
    fecha_en: "",
    video_en: "",
    title_es: "Webinar: Negociaciones en salud, creando valor compartido",
    text_es: "¿Cómo crear y capturar valor en negociación con recursos limitados?",
    fecha_es: "26 Enero, 2021",
    video_es: 'https://www.youtube.com/embed/CwrWVh3DZrI',
  },
];
export default dataBlog