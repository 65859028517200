import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-translate';
import { Helmet } from 'react-helmet';

import dataBlog from '../../components/ObjectData/dataBlog';

import IconLinkedin from '../../assets/images/Icon/linkedin.png';

import './InsightsVideo.css';

function InsightsVideo(props) {
  let { t } = props;
  const lang = localStorage.getItem('Lang');
  let id_video = props.match.params.id;
  const DataVideo = dataBlog.filter((video) => video.id + "" === id_video + "");
  const { match: { params } } = props
  useEffect(() => {
    const scroll = (value) => {
      if (value === undefined) {
        window.scroll({
          top: 80,
          left: 0,
          behavior: "smooth",
        });
      }
    };
    scroll(params.scroll);
  }, [params.scroll]);

  return (
    <div className="InsightsVideo__page">
      <Helmet>
        <title>Negotiation by Design | Blog Video</title>
      </Helmet>
      {DataVideo.map((data) => (
        <Fragment key={data.id}>
          <div className="InsightsVideo__header">
            <div className="InsightsVideo__container--image">
              <img
                src={data[`image_${lang}`]}
                alt="ImagenHeader"
              />
            </div>
          </div>
          <div className="InsightsVideo__content">
            <div className="Content__card">
              <div className="Content__card--header">
                <h5>{data[`title_${lang}`]}</h5>
                <p>{data[`text_${lang}`]}</p>
              </div>
              <div className="Content__card--body">
                <div
                  className="video"
                  style={{
                    position: 'relative',
                    paddingBottom: '56.25%',
                    paddingTop: 25,
                    height: 0,
                  }}
                >
                  <iframe
                    title="Reproductor de video"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                    src={data[`video_${lang}`]}
                    frameBorder="0"
                  />
                </div>
              </div>
              <div className="Content__card--footer">
                <p>{t('card_text_end')}</p>
                <div className="Card__footer--redes">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/pablorestrepo/"
                  >
                    <img src={IconLinkedin} alt="Icon__likedin" />
                  </a>
                </div>
                <div>
                  <Link className="Card__footer--back" to={`/blog`}>
                    {t('text_back')}
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="InsightsVideo__footer--title">
              <h4>{t('title_suggestions')}</h4>
            </div>
            <div className="InsightsVideo__footer--list">
              <div className="List_card">
                <div>
                  <img src={Artboard1} alt="" />
                </div>
                <span>{t('card_fecha_1')}</span>
                <div>
                  <a>{t('card_title_1')}</a>
                </div>
              </div>
              <div className="List_card">
                <div>
                  <img src={Artboard1} alt="" />
                </div>
                <span>{t('card_fecha_1')}</span>
                <div>
                  <a>{t('card_title_1')}</a>
                </div>
              </div>
              <div className="List_card">
                <div>
                  <img src={Artboard1} alt="" />
                </div>
                <span>{t('card_fecha_1')}</span>
                <div>
                  <a>{t('card_title_1')}</a>
                </div>
              </div>
            </div> */}
          </div>
        </Fragment>
      ))}
    </div>
  );
}

export default translate('insights')(InsightsVideo);