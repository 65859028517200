import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Element, } from 'react-scroll';
import { translate } from 'react-translate';

import './Workshop.css';
import Header from '../../components/Header';
import Image from '../../assets/images/Blue-canvas-02.png';
import ImageES from '../../assets/images/banner/Es/NbD_ES-02.png';
import SliderClients from '../../components/Sliders/SliderClients';

function Workshops(props) {
  let { t } = props;
  const lang = localStorage.getItem('Lang');
  const { match: { params } } = props
  useEffect(() => {
    const scroll = (value) => {
      if (value === undefined) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    };
    scroll(params.scroll);
  }, [params.scroll]);

  return (
    <div className="Workshop__page">
      <Helmet>
        <title>Negotiation by Design | Workshop</title>
      </Helmet>
      <Header
        title="title_workshop"
        paragraph="paragraph_workshop"
        text="text_workshop"
        scroll="workshop"
        isVideo
      />
      <Element name="workshop"></Element>
      <div className="Workshop__section--start">
        <div className="Section__start--title">
          <h2>{t('title_1')}</h2>
          <p>{t('subtitle_1')}</p>
        </div>
        <div className="Section__start--list">
          <div className="Start__list">
            <ul className="Start__list--ul">
              <li>{t('list_item_1')}</li>
              <li>{t('list_item_2')}</li>
              <li>{t('list_item_3')}</li>
            </ul>
          </div>
          <div className="Start__list">
            <ul className="Start__list--ul">
              <li>{t('list_item_4')}</li>
              <li>{t('list_item_5')}</li>
            </ul>
          </div>
        </div>
        <div className="Section__start--text">
          <div className="Text__list">
            <h4>{t('title_2')}</h4>
            <ul className="Start__list--ul">
              <li>{t('list_item_6')}</li>
              <li>{t('list_item_7')}</li>
              <li>{t('list_item_8')}</li>
            </ul>
          </div>
          <div className="Text__image">
            <img src={lang === 'en' ? Image : ImageES} alt="" />
          </div>
        </div>
      </div>
      <div className="Workshop__client">
        <div className="Workshop__section--cards">
          <h3>{t('title_3')}</h3>
          <div className="Section__cards">
            <SliderClients />
          </div>
          <div className="Workshop__section--end">
            <h5 className="Section__end--title">
              {t('title_4')}{' '}
              <span>
                <Link to="/contact">{t('title_5')}</Link>
              </span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default translate('workshop')(Workshops);
