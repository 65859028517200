import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLang } from "../Context";

import "./Menu.css";

import MenuItem from "../MenuList/index";
import logo from "../../assets/images/logo-nbd.png";
import IconClose from "../../assets/images/IconClose.png";
import Overlay from '../Overlay/Overlay';

import IconLinkedin from "../../assets/images/Icon/linkedin.png";
import IconLang from "../../assets/images/Icon/iconLang.png";
function Menu(props) {
  const { lang, setLang } = useLang();

  const [mostrarnav, setmostrarnav] = useState("Menu__page");
  const [open, setOpen] = useState(props.open);
  const dataMenu = [
    { id: '1', name: 'enterprise', route: '/enterprise' },
    { id: '2', name: 'tools', route: '/tools' },
    { id: '3', name: 'blog', route: '/blog' },
    { id: '4', name: 'about', route: '/about' },
    { id: '5', name: 'contact', route: '/contact' },
  ];
  const handleLang = (newLang) => {
    setLang(newLang);
    localStorage.setItem("Lang", newLang);
  };
  useEffect(() => {
    window.onscroll = function () {
      let actual = window.pageYOffset;
      if (actual > 0) {
        setmostrarnav("Menu__Scroll");
      } else {
        setmostrarnav("Menu__page");
      }
    };
  }, [mostrarnav]);
  const openn = (e) => {
    setOpen("Open__menu");
  };
  const OnClose = (e) => {
    setOpen("");
  };
  return (
    <div className={mostrarnav}>
      <nav className="Menu__content">
        <Overlay overlay={open} onClick={OnClose} />
        <div className="Menu__content_page">
          <div className="Menu__content--logo">
            <Link to="/">
              <img className='Menu__content_img' src={logo} alt="Logo Akelab" />
            </Link>
          </div>
          <div className={`Menu__content--items ${open}`}>
            <div className="Items__views">
              <div onClick={OnClose} className="Item__close">
                <div className="Item__close--button">
                  <img
                    className="Button__logo--nbd"
                    src="https://wisengine-nbd.s3.amazonaws.com/Logo-blanco-01.png"
                    alt="Icon NBD Blanco"
                  />
                  <img
                    className="Button__logo--close"
                    src={IconClose}
                    alt="Icon close"
                  />
                </div>
              </div>
              <ul onClick={OnClose} className="Menu__container_items">
                {dataMenu.map((item, index) => (
                  <MenuItem key={index} route={item.route} name={item.name} />
                ))}
              </ul>
              <div className="Items__socialMediaLang">
                <div className="Item__socialMedia">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/pablorestrepo/"
                  >
                    <img src={IconLinkedin} alt="Icon NBD Linkedin" />
                  </a>
                </div>
                <div className="Item__languages dropdown">
                  <img src={IconLang} alt="Icon Lang" />
                  <div className="Item__lang">{lang === "es" ? "ES" : "EN"}</div>
                  <div onClick={OnClose} className="dropdown-content">
                    <div className="Item__languages--es" onClick={(e) => handleLang("es")}>
                      <h6>Es</h6>
                    </div>
                    <div className="Item__languages--en" onClick={(e) => handleLang("en")}>
                      <h6>En</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Item__menu" onClick={openn}>
            <span>&#9776;</span>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Menu;
